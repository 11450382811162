import {callAPI} from "../../services/apiService";
import {Toast} from "./Toast";
import React, {useState} from "react";
import {useUser} from "../../context/userContext";

const TermsAndConditions = () => {
    const [loader, setLoader] = useState({
        profileUpdate: false,
    });
    const { user, setUser } = useUser();


    const handleSubmit = async (e, value) => {
        e.preventDefault();
        if (!user) {
            return;
        }
        try {
            setLoader({ ...loader, profileUpdate: true });
            if (!user?.firstName || !value) {
                setUser({
                    ...user,
                    acceptedTermsAndConditions: value,
                    closeTermsAndConditions: !value,
                });
                setLoader({ ...loader, profileUpdate: false });
                return;
            }
            const response = await callAPI("user/updateUser", {
                email: user?.email,
                acceptedTermsAndConditions: value,
            });
            if (response?.status === 200) {
                Toast("Terms and Conditions accepted", false);
                setUser({
                    ...user,
                    acceptedTermsAndConditions: value,
                    closeTermsAndConditions: !value,
                });
            }
            setLoader({ ...loader, profileUpdate: false });
        } catch (err) {
            Toast("Terms and Conditions not accepted", true);
            setLoader({ ...loader, profileUpdate: false });
        }
    };

    return (
        <div>
            <div className="terms-and-conditions text-sm text-gray-800 overflow-y-auto max-h-96 max-w-96">
                <div className="px-4 sm:px-10 md:px-20 lg:px-40">
                    <h2 className="text-lg font-bold text-gray-900 mb-2">Non-Disclosure Agreement (NDA)</h2>
                    <p className="mb-4">
                        <span className="font-bold">This Non-Disclosure Agreement</span> ("Agreement") is entered into
                        by
                        and between <span className="font-bold">Build My Story, Inc.</span> ("BMS"), a company organized
                        under the laws of the State of California, and any user ("User") who accesses or uses the Build
                        My
                        Story website ("Website"). By accepting the terms and conditions of the Website and Web App, the
                        User agrees to be bound by the terms of this Agreement.
                    </p>

                    <h3 className="font-semibold text-gray-900 mb-2">1. Confidential Information</h3>
                    <p className="mb-2">
                        For the purposes of this Agreement, "Confidential Information" includes but is not limited to:
                    </p>
                    <ul className="list-disc list-inside mb-4 pl-4">
                        <li>
                            Any non-public technical, business, creative, or financial information disclosed by BMS,
                            whether
                            written, electronic, or verbal.
                        </li>
                        <li>
                            Proprietary information related to the Website and Web App, including but not limited to its
                            design, source code, content, functionality, and any related intellectual property.
                        </li>
                        <li>User-generated content created or submitted while using the Website.</li>
                    </ul>
                    <p className="mb-4">
                        Confidential Information does not include information that:
                    </p>
                    <ol className="mb-4">
                        <li>a. Is or becomes publicly known through no breach of this Agreement;</li>
                        <li>b. Was lawfully known to the User before disclosure by BMS;</li>
                        <li>
                            c. Is independently developed by the User without reference to BMS's Confidential
                            Information;
                        </li>
                        <li>
                            d. Is required to be disclosed by law, provided that the User promptly notifies BMS to allow
                            for
                            a protective order or other appropriate remedy.
                        </li>
                    </ol>

                    <h3 className="font-semibold text-gray-900 mb-2">2. Obligations of the User</h3>
                    <p className="mb-2">
                        The User agrees to:
                    </p>
                    <ul className="list-disc list-inside mb-4 pl-4">
                        <li>
                            Use Confidential Information solely for the purposes of accessing and using the Website, Web
                            App
                            and its services.
                        </li>
                        <li>
                            Not disclose Confidential Information to any third party without prior written consent from
                            BMS.
                        </li>
                        <li>Exercise reasonable care, equivalent to the care used to protect their own confidential
                            information, to prevent unauthorized disclosure of BMS's Confidential Information.
                        </li>
                    </ul>

                    <h3 className="font-semibold text-gray-900 mb-2">3. Intellectual Property</h3>
                    <p className="mb-4">
                        All Confidential Information disclosed remains the exclusive property of BMS. This Agreement
                        does
                        not grant the User any rights, licenses, or ownership interest in the Confidential Information,
                        except for the limited purpose of using the Website and Web App in accordance with its terms and
                        conditions.
                    </p>

                    <h3 className="font-semibold text-gray-900 mb-2">4. Term</h3>
                    <p className="mb-4">
                        This Agreement shall remain in effect as long as the User accesses or uses the Website and Web
                        App
                        and for a period of <span className="font-bold">two (2) years</span> thereafter.
                    </p>

                    <h3 className="font-semibold text-gray-900 mb-2">5. Remedies</h3>
                    <p className="mb-4">
                        The User acknowledges that unauthorized use or disclosure of Confidential Information may result
                        in
                        irreparable harm to BMS. In such cases, BMS shall have the right to seek injunctive relief in
                        addition to any other remedies available under law.
                    </p>

                    <h3 className="font-semibold text-gray-900 mb-2">6. Governing Law</h3>
                    <p className="mb-4">
                        This Agreement is governed by and construed in accordance with the laws of the <span
                        className="font-bold">State of California</span>, without regard to its conflict of laws
                        principles.
                    </p>

                    <h3 className="font-semibold text-gray-900 mb-2">7. Acceptance</h3>
                    <p className="mb-4">
                        By accepting the terms and conditions of the Website and Web App, the User agrees to be bound by
                        the
                        terms of this Non-Disclosure Agreement.
                    </p>
                </div>
            </div>

            <div className="flex justify-center w-full space-x-4 mt-6">
                <button
                    className="bg-storyblueButton text-white font-medium px-6 py-2 rounded-md shadow hover:bg-storyblue transition"
                    onClick={(event) => handleSubmit(event, true)}
                >
                    Accept
                </button>

                <button
                    className="bg-gray-200 text-gray-700 font-medium px-6 py-2 rounded-md shadow hover:bg-gray-300 transition"
                    onClick={(event) => handleSubmit(event, false)}
                >
                    Decline
                </button>
            </div>
        </div>
    );
};
export default TermsAndConditions;
