import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAPI } from "../../services/apiService";
import Button from "../../components/Common/Button";
import { goToTopOfPage, scrollToArea } from "../../helper/helper";
import { Toast } from "../../components/Common/Toast";
import {
  DEDUCT_CREDITS_PER_IMAGE,
  HTTP_STATUS_200,
  HTTP_STATUS_400,
  HTTP_STATUS_403,
  MID_JOURNEY_MODE_RELAX,
} from "../../helper/Constant";
import { useApp } from "../../context/appContext";
import { useStory } from "../../context/storyContext";
import DummyPic from "../../components/Image holder Icon.png";
import { useMediaQuery } from "react-responsive";

import Modal from "../../components/Common/Modal";

const EditImageTextIllustration = ({
  bookId,
  pageIndex,
  pageData,
  type,
  index,
  setShowEditTextIllustration,
  // userCreditBalance,
  // setUserCreditBalance,
  setShowLowCreditModal,
  displayBookPages,
  // scrollToStory,
  setStateData,
  stateData,
  targetRefs,
  // setShowPromptLibrary,
  setShowStoryForm,
  setCurrentStep,
  setimageCharacterPrompt,
  editing,
  setShowCharacterLibrary,
}) => {
  const navigate = useNavigate();
  //const { setIllustrationCharacter, setStoryNewCharacter, illustrationCharacter } = useStory();
  const { setStoryNewCharacter } = useStory();
  const textRef = useRef(null);
  const sceneRef = useRef(null);
  const [loader, setLoader] = useState(false);

  const [showRelaxModeModal, setShowRelaxModeModal] = useState(false);

  const { bookData } = useApp();
  const { storyData, setStoryData } = useStory();

  // console.log("storyData ", userCreditBalance);

  useEffect(() => {
    goToTopOfPage();
    setStateData({ ...stateData, showDownloadPDF: false });
  }, []);

  useEffect(() => {
    if (textRef.current && pageData) {
      const textLength = textRef.current.value.length;
      textRef.current.setSelectionRange(textLength, textLength);
      textRef.current.focus();
    }
    if (sceneRef.current && pageData) {
      const sceneLength = sceneRef.current.value.length;
      sceneRef.current.setSelectionRange(sceneLength, sceneLength);
      sceneRef.current.focus();
    }
  }, [pageData]);

  const callImageGenerator = async (pageKey, storyPrompt, storyText) => {
    setLoader(true);
    const response = await callAPI("api/generate-single-image", {
      bookId,
      pageKey,
      storyPrompt,
      imagePromptCharacterUrl: pageData?.imagePromptCharacterUrl
        ? pageData?.imagePromptCharacterUrl
        : bookData?.characterPortrait,
    });
    if (response?.status == HTTP_STATUS_200) {
      // console.log("call image generator", response?.status);
      Toast("New Image Will Display Soon");
      setShowEditTextIllustration(false);
      // setIllustrationCharacter(null);
      setStoryData((prev) => ({ ...prev, illustrationCharacter: null }));
      closeComponent();
    } else if (response?.error) {
      Toast(response?.error, true);
    } else if (response?.status == HTTP_STATUS_400) {
      setStoryData({
        ...storyData,
        creditsPerBookLimit: parseInt(storyData?.creditsPerBookLimit) + DEDUCT_CREDITS_PER_IMAGE,
      });
      // setUserCreditBalance(userCreditBalance + DEDUCT_CREDITS_PER_IMAGE);
      Toast(response?.message, true);
      setShowLowCreditModal(true);
      closeComponent();
    } else Toast("Image Generation Failed! Try Again!", true);
    setLoader(false);
    // closeComponent();
  };
  const closeComponent = () => {
    scrollToArea(targetRefs, pageIndex);
    setStateData({ ...stateData, showDownloadPDF: true });
  };

  const updateText = async (updatedText, updatedScene) => {
    try {
      setLoader(true);
      await callAPI("api/update-book-text", {
        bookId,
        pageIndex,
        updatedText,
        updatedScene,
      });
      displayBookPages();
    } catch (error) {
      console.error("Error updating book text:", error);
    } finally {
      setLoader(false);
      setShowEditTextIllustration(false);
      // setIllustrationCharacter(null);
      setStoryData((prev) => ({ ...prev, illustrationCharacter: null }));
      closeComponent();
    }
  };
  // console.log("bookdata", bookData, "storyData======>>>", storyData);
  const handleUpdate = async (generateNewImage = true) => {
    if (
      bookData?.modeIllustrationGeneration === MID_JOURNEY_MODE_RELAX ||
      (parseInt(bookData?.creditsPerBookLimit) >= DEDUCT_CREDITS_PER_IMAGE &&
        parseInt(storyData?.creditsPerBookLimit) >= DEDUCT_CREDITS_PER_IMAGE)
    ) {
      // console.log("handleupdate if");
      const creditsToDeduct =
        bookData?.modeIllustrationGeneration === MID_JOURNEY_MODE_RELAX
          ? 0
          : parseInt(DEDUCT_CREDITS_PER_IMAGE);

      setStoryData({
        ...storyData,
        creditsPerBookLimit: parseInt(bookData?.creditsPerBookLimit) - creditsToDeduct,
      });
      // setUserCreditBalance(userCreditBalance - DEDUCT_CREDITS_PER_IMAGE);
      const updatedText = textRef.current ? textRef.current.value : "";
      const updatedScene = sceneRef.current ? sceneRef.current.value : "";
      if (pageData.scene != updatedScene || pageData.text != updatedText)
        await updateText(updatedText, updatedScene);
      if (generateNewImage) {
        await callImageGenerator(pageIndex, updatedScene, updatedText);
      }
    } else {
      // console.log("handleupdate else");
      // Toast("Additional Image Credits Needed!", true);
      setShowRelaxModeModal(true);
      // navigate("/ImageTokens");
    }
  };
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const [text, setText] = useState(pageData?.text || "");

  const handleTextChange = (event) => {
    const wordLimit = parseInt(bookData?.wordRange);
    const words = event.target.value.split(/\s+/);

    // Check if word limit is exceeded before updating the text
    if (words.length > wordLimit) {
      Toast(`Word count for current book is ${wordLimit}!`, true);
      return; // Exit without updating text to stop additional input
    } else {
      setText(event.target.value); // Update text if within limit
    }
  };

  return (
    <>
      {/* <div
        className="flex justify-evenly gap-8 md:gap-12 flex-wrap md:flex-nowrap text-left m-2 mb-8 text-sm text-storyblue 
  lg:animate__animated lg:animate__slideInRight"
      > */}

      {showRelaxModeModal && (bookData?.modeIllustrationGeneration !== MID_JOURNEY_MODE_RELAX)  && (
        <Modal
          // title="High Speed Image Renewal Alert"
          description="Hey, we’ve noticed you’re enjoying editing your book! To keep things running smoothly, we’re switching to relaxed mode, which means image generation might take a little longer. Take your time and keep creating!"
          doProcess={() => navigate("/ImageTokens")}
          // submitButtonTitle="re-store image renewal speed"
          // showCloseButtonFunction={showCloseButtonRelaxModalFunction}
          showCloseButton={false}
          showFreeBookViaRelaxMode={true}
          showSubmitButton={false}
        />
      )}
      <div
        className={`${
          isLargeScreen
            ? "animate__animated animate__slideInRight flex justify-evenly gap-8 md:gap-12 flex-wrap md:flex-nowrap text-left m-2 mb-8 text-sm text-storyblue"
            : "flex justify-evenly gap-8 md:gap-12 flex-wrap md:flex-nowrap text-left m-2 mb-8 text-sm text-storyblue"
        }`}
      >
        {/*  */}
        <div className="w-full">
          <div className="flex justify-between">
            <div className="w-full">
              <h2 className="text-left text-xl md:text-2xl mb-4 font-bold text-storyblue">
                Edit Page {index + 4}
                {type === "page" ? `- ${pageData?.index + 1}` : ""}
              </h2>
              <div
                className={`grid ${type === "page" ? "grid-cols-3 " : "grid grid-cols-2 gap-4"} md: gap-6`}
              >
                <div
                  className="col-span-2"
                  style={{
                    display: type === "editText" || type === "page" ? "block" : "none",
                  }}
                >
                  <h3 className="w-full text-sm text-gray-500 font-bold">Page Text </h3>
                  <textarea
                    ref={textRef}
                    value={text}
                    onInput={handleTextChange}
                    className="h-36 md:h-96 text-lg md:text-2xl p-4 border-gray-400 border-2 text-gray-800 w-full"
                  />
                </div>
                <div className="my-2" style={{ display: type === "page" ? "block" : "none" }}>
                  <img
                    src={pageData?.imageURLwebp}
                    alt="Page"
                    className="mx-auto mt-4 w-48 md:w-96 h-48 md:h-96"
                  />
                </div>
                <div
                  className="col-span-2"
                  style={{
                    display: type === "editPrompt" || type === "page" ? "block" : "none",
                  }}
                >
                  <h3 className="text-sm text-gray-500 font-bold pb-2">
                    This scene description allows you to modify this pages illustration featuring the
                    character selected on the right.
                  </h3>
                  <textarea
                    autoFocus
                    ref={sceneRef}
                    defaultValue={pageData?.scene}
                    className="h-36 md:h-96 text-lg md:text-2xl p-4 border-gray-400 border-2 text-gray-800 w-full"
                    // className="w-full rounded-md text-sm md:text-xl p-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-wrap gap-2 md:gap-4 mt-4">
            <Button
              title=" Close"
              onclick={() => {
                closeComponent();
                setShowEditTextIllustration(false);
                // setIllustrationCharacter(null);
                setStoryData((prev) => ({ ...prev, illustrationCharacter: null }));
              }}
              className="submit-button w-60 flex justify-center gap-3 max-w-7xl bg-storygreen  text-white font-bold rounded-md text-base h-11 items-center"
            />
            <Button
              title={type === "editText" ? "Update Text" : "New Image"}
              loader={loader}
              onclick={() => {
                handleUpdate(type === "editText" ? false : true);
              }}
              className="submit-button w-60 flex justify-center gap-3 max-w-7xl bg-storyblue text-white font-bold rounded-md text-base h-11 items-center"
            />
          </div>
        </div>
        <div
          className="flex flex-col gap-[4.75rem] lg:gap-10 w-full md:w-[50%]"
          style={{
            display: type === "editPrompt" || type === "page" ? "flex" : "none",
          }}
        >
          <h2 className="text-left text-xl md:text-2xl font-bold text-storyblue whitespace-nowrap pb-2">
            Character for Illustration
          </h2>
          <div className="flex flex-col items-center gap-4">
            <img
              src={
                pageData?.imagePromptCharacterUrl
                  ? pageData?.imagePromptCharacterUrl
                  : bookData?.characterPortrait
              }
              className="md:h-[24rem] rounded-md"
            />
            <Button
              title={"Select New Character"}
              onclick={() => {
                setStoryNewCharacter(true);
                // setIllustrationCharacter(editing);
                setimageCharacterPrompt(editing);
                setShowCharacterLibrary(false);
                setShowStoryForm(true);
                setCurrentStep(2);

                setStoryData((prev) => ({
                  ...prev,
                  illustrationCharacter: editing,
                  uploadedImage: null,
                  regeneratedNewCharacter: false,
                  newRefImageUploaded: false,
                }));
              }}
              className="submit-button w-60 flex justify-center gap-3 max-w-7xl bg-storyblue text-white font-bold rounded-md text-base h-11 items-center"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EditImageTextIllustration;
