import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../context/userContext";
import { Formik } from "formik";
import Loader from "../../components/Common/Loader";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "../../components/Common/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SignUpSchema,
  checkSubscribedToPlan,
  getPlans,
  getstoryConfig,
  handleGoogleLogin,
  handleCouponChange,
  handleGoogleLogin_NEW,
  SignUpViaGoogleSchema,
} from "../Builder/builderHelpers";
import {
  COUPON_FOR_BOOK_PURCHASE,
  COUPON_FOR_PLAN_PURCHASE,
  GOAFFPRO_PUBLIC_KEY,
  HTTP_STATUS_200,
  HTTP_STATUS_400,
  HTTP_STATUS_403,
  LOCAL_STORAGE_TOKEN,
  PROMO_PLAN,
} from "../../helper/Constant";
import { Toast } from "../../components/Common/Toast";
import { callAPI } from "../../services/apiService";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { capitalizeFirstCharacter, encryptToken, replaceFromString } from "../../helper/helper";
import { auth } from "../../firebase";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";

import { init, trackPageView, trackConversion } from "goaffpro-client";
import Guide from "../../components/Common/Guide";

init(GOAFFPRO_PUBLIC_KEY);

const SignUp = ({ signUpStep, setSignUpStep, plan = null }) => {
  const { setUser, user, showLogin, setShowLogin, getUserDetails, signupViaGoogle, setSignupViaGoogle } =
    useUser();
  const stripe = useStripe();
  const elements = useElements();
  const [signupLoader, setSignupLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  const [showSignupConfirmPassword, setShowSignupConfirmPassword] = useState(false);
  const [selectedPlanDescription, setSelectedPlanDescription] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [storyconfig, setStoryConfig] = useState({});
  const [planSubscribed, setPlanSubscribed] = useState(false);
  const [promotionalEmail, setPromotionalEmail] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [placeholder, setPlaceholder] = useState("Promo Code (Optional)");
  const [couponCode, setCouponCode] = useState(null);
  const [couponClass, setCouponClass] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [couponLoader, setCouponLoader] = useState(false);
  const [price, setPrice] = useState(null);
  const [promoPlan, setPromoPlan] = useState(null);
  const [couponDescription, setCouponDescription] = useState(null);
    const [trialDays, setTrialDays] = useState(null);
  // const { plan } = useParams();
  // const [signupViaGoogle, setSignupViaGoogle] = useState(null);

  const [loaders, setLoaders] = useState({
    characterGenerating: false,
    characterRegenerate: false,
    planExist: false,
  });

  const signUpInitailValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAdult: false,
    terms: false,
    plan: "",
    promoCode: "",
    updateEmail: false,
    promotionalEmail: false,
  };
  // console.log("signupViaGoogle", signupViaGoogle);

  useEffect(() => {
    getPlans(setPlans, null, true);
    getstoryConfig(setStoryConfig);

    //goaffpro page tracking
    trackPageView();
  }, []);
  //  console.log("usr", parseFloat(couponDiscount), parseFloat(price));
  useEffect(() => {
    // if (user) planExist();
    if (user) checkSubscribedToPlan(user, setLoaders, setPlanSubscribed, loaders);
  }, [user]);

  useEffect(() => {
    const selectedObj = plans?.find((value) => value.id === selectedPlan);
    setSelectedPlanDescription(selectedObj);
    setPrice(selectedObj?.cost);
  }, [selectedPlan, plans]);

  useEffect(() => {
    //check if user redirected wih  plan from lnding page(PRomo or other)
    if (plan) {
      setSelectedPlan(plan);
      if (plan === PROMO_PLAN) {
        localStorage.setItem(PROMO_PLAN, true);
        setPromoPlan(plan);
      }

      if (signupViaGoogle)
        setSignupViaGoogle((prev) => {
          return {
            ...prev,
            plan: plan,
          };
        });

        
    }
  }, [plan]);


//check if plan redirected is trial plan
  useEffect(() => {
    setTrialDays(selectedPlanDescription?.trialDays > 0 ? selectedPlanDescription?.trialDays : null);
  }, [selectedPlanDescription]);

  const handlePlanChange = (event) => {
    // const selectedIndex = event.target.value - 1;
    const selectedObj = plans.find((value) => value.id === event.target.value);
    setSelectedPlanDescription(selectedObj);
    setTrialDays(selectedObj?.trialDays > 0 ? selectedObj?.trialDays : null);
    setSelectedPlan(event.target.value);
  };
  const handleApplyPromoCode = async () => {
    setCouponCode(null);
    setPrice(selectedPlanDescription?.cost);
    setCouponLoader(true);
    const response = await callAPI("user/coupon-validate", {
      couponCode,
      amount: selectedPlanDescription?.cost,
      requestType: COUPON_FOR_PLAN_PURCHASE,
    });

    if (response.status === HTTP_STATUS_200) {
      setCouponClass("text-storygreen");
      let discountedPrice = parseFloat(response?.data?.discount).toFixed(2);
      setCouponDescription(response?.data?.couponDescription);
      setCouponDiscount(discountedPrice);
      setPrice(parseFloat(response?.data?.price).toFixed(2));
      // if (response?.data?.price < 1) Toast("Invalid Charge Amount", true);
      // else
      Toast("Coupon Applied");
      setCouponCode(couponCode);

      setCouponLoader(false);
    } else {
      setCouponCode(null);
      setCouponClass("text-storyred");
      setCouponDiscount(null);
      Toast("Invalid Coupon", true);
      setCouponLoader(false);
    }
  };

  const handleFormSubmit = async (values) => {
    setSignupLoader(true);
    try {
      if (promoPlan) {
        //  if (!stripe || !elements) {
        //    Toast("Stripe not loaded", true);
        //    setSignupLoader(false);
        //    return;
        //  }

        //  const resultStripe = await stripe.createPaymentMethod({
        //    type: "card",
        //    card: elements.getElement(CardElement),
        //    billing_details: {
        //      email: values.email,
        //    },
        //  });
        const userData = {
          email: signupViaGoogle?.email || values?.email,
          password: values?.password || "",
          passwordConfirm: values?.confirmPassword || "",
          firstName: signupViaGoogle?.firstName || values?.firstName,
          lastName: signupViaGoogle?.lastName || values?.lastName,
          authType: signupViaGoogle?.authType || "email/password",
          plan: signupViaGoogle?.plan || selectedPlan,
          promoCode: couponCode,
          isAdult: values?.isAdult || true,
          promotionalEmail: values?.promotionalEmail,
          updateEmail: values?.updateEmail,
          paymentMethod: null,
          // paymentMethod: resultStripe?.paymentMethod?.id,
          firebaseId: signupViaGoogle?.firebaseId || "",
          ...(user.acceptedTermsAndConditions ? { acceptedTermsAndConditions: user.acceptedTermsAndConditions } : {}),
        };

        const response = await callAPI("api/createUser", userData);
        if (response?.error || response?.status === HTTP_STATUS_403) {
          Toast("User Not Registered", true);
          setSignupLoader(false);
        } else if (response?.status === HTTP_STATUS_400) {
          Toast("Please Provide a Valid Email", true);
          setSignupLoader(false);
        } else if (response?.verified) {
          Toast(response?.message, true);
          setSignupLoader(false);
        } else if (!signupViaGoogle?.firebaseId) {
          const firebaseResponse = await createUserWithEmailAndPassword(auth, values.email, values.password);
          setUser(firebaseResponse?.user);

          await callAPI("api/update-firebase-id", {
            email: userData?.email,
            firebaseId: firebaseResponse?.user?.uid,
          });

          const idTokenResult = await firebaseResponse?.user?.getIdTokenResult();
          const idToken = idTokenResult.token;

          const encryptedToken = encryptToken(idToken, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);

          localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);

          Toast("User Registered Successfully");
        }
      } else {
        if (!stripe || !elements) {
          Toast("Stripe not loaded", true);
          setSignupLoader(false);
          return;
        }

        const resultStripe = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            email: values.email,
          },
        });
        console.log("resultStripe", resultStripe);

        if (resultStripe?.error) {
          Toast("Error Processing Card details", true);
          setSignupLoader(false);
        } else {
          // console.log("signupViaGoogle", signupViaGoogle);

          const userData = {
            email: signupViaGoogle?.email || values?.email,
            password: values?.password || "",
            passwordConfirm: values?.confirmPassword || "",
            firstName: signupViaGoogle?.firstName || values?.firstName,
            lastName: signupViaGoogle?.lastName || values?.lastName,
            authType: signupViaGoogle?.authType || "email/password",
            plan: signupViaGoogle?.plan || selectedPlan,
            promoCode: couponCode,
            isAdult: values?.isAdult || true,
            promotionalEmail: values?.promotionalEmail,
            updateEmail: values?.updateEmail,
            paymentMethod: resultStripe?.paymentMethod?.id,
            firebaseId: signupViaGoogle?.firebaseId || "",
            ...(user.acceptedTermsAndConditions ? { acceptedTermsAndConditions: user.acceptedTermsAndConditions } : {}),
          };

          const response = await callAPI("api/createUser", userData);
          if (response?.error || response?.status === HTTP_STATUS_403) {
            Toast("User Not Registered", true);
            setSignupLoader(false);
          } else if (response?.status === HTTP_STATUS_400) {
            Toast("Please Provide a Valid Email ", true);
            setSignupLoader(false);
          } else if (response?.verified) {
            Toast(response?.message, true);
            setSignupLoader(false);
          } else if (!signupViaGoogle?.firebaseId) {
            const firebaseResponse = await createUserWithEmailAndPassword(
              auth,
              values.email,
              values.password
            );
            setUser(firebaseResponse?.user);
            //setUser(response?.user);

            await callAPI("api/update-firebase-id", {
              email: userData?.email,
              firebaseId: firebaseResponse?.user?.uid,
            });

            const idTokenResult = await firebaseResponse?.user?.getIdTokenResult();
            const idToken = idTokenResult.token;

            const encryptedToken = encryptToken(idToken, process.env.REACT_APP_TOKEN_ENCRYPT_KEY);

            localStorage.setItem(LOCAL_STORAGE_TOKEN, encryptedToken);
            //goaffpro track conversion with Email/password sign up
            trackConversion({
              // id: "1001", // the internal order ID of your system (useful if you are using REST JSON file)
              number: resultStripe?.paymentMethod?.id, // human readable order number displayed to the admins
              total: selectedPlanDescription?.cost, // the order total (the final amount that the customer paid)
              // subtotal: 850, // order subtotal (order total minus shipping and taxes)
              // discount: 50, // the discount received by the customer
              // tax: 100, // the tax charged on the order
              // shipping: 50, // the shipping charged on the order
              currency: "USD", //ISO-4217 three letter currency code of the order
              // date: "2021-04-27T17:06:55.450Z",
              /*customer: {
              // customer details
              first_name: "John",
              last_name: "Doe",
              email: "johndoe@example.com",
              phone: "+1 555-111-222", // optional
              is_new_customer: true, //optional
            },//*/
              // coupons: ["CRED"],
            });
            Toast("User Registered Successfully");
            setShowLogin(false);
          } else {
            //goaffpro track conversion with google Sign up
            trackConversion({
              // id: "1001", // the internal order ID of your system (useful if you are using REST JSON file)
              number: resultStripe?.paymentMethod?.id, // human readable order number displayed to the admins
              total: selectedPlanDescription?.cost, // the order total (the final amount that the customer paid)
              // subtotal: 850, // order subtotal (order total minus shipping and taxes)
              // discount: 50, // the discount received by the customer
              // tax: 100, // the tax charged on the order
              // shipping: 50, // the shipping charged on the order
              currency: "USD", //ISO-4217 three letter currency code of the order
              // date: "2021-04-27T17:06:55.450Z",
              /*customer: {
              // customer details
              first_name: "John",
              last_name: "Doe",
              email: "johndoe@example.com",
              phone: "+1 555-111-222", // optional
              is_new_customer: true, //optional
            },//*/
              // coupons: ["CRED"],
            });
          }
        }
      }
      setSignupLoader(false);
    } catch (err) {
      Toast("Error Signing Up! Check details and try again!", true);
      setSignupLoader(false);
    }
  };

  const updateUserEmail = (email) => {
    setUser({email})
  }

  return (
    <>
      <div className="m-4">
        {signUpStep == 1 ? (
          <div className="flex flex-col gap-2">
            {/* <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">Let&apos;s Build Your Story</h2> */}
            <div className=" flex items-center align-end mb-4">
              <h2 className="text-left text-4xl  font-bold text-storyblue">Let&apos;s Build Your Story</h2>
              <div className="mt-2">
                <Guide />
              </div>
            </div>

            <button
              disabled={googleLoader}
              onClick={() => {
                handleGoogleLogin_NEW(
                  setGoogleLoader,
                  setUser,
                  getUserDetails,
                  promotionalEmail,
                  updateEmail,
                  setSignUpStep,
                  setSignupViaGoogle
                );
              }}
              className="w-full h-11 flex justify-center gap-4 bg-white text-storyblue font-bold rounded-[1.5rem] text-base items-center cursor-pointer"
            >
              <img src="/google-icon.png" alt="google" className="w-[26px]" />
              Sign Up with Google
              <Loader loader={googleLoader} />
            </button>
            {/* <div>
              <div className="flex items-baseline">
                <input
                  type="checkbox"
                  name="promotionalEmail"
                  className="mr-2 rounded-md"
                  value={promotionalEmail}
                  onChange={() => setPromotionalEmail(!promotionalEmail)}
                />
                <label className="flex text-start text-[#757575]">
                  I would like to receive promotional emails.
                </label>
              </div>
            </div> */}
            {/* <div>
              <div className="flex items-baseline">
                <input
                  type="checkbox"
                  name="updateEmail"
                  value={updateEmail}
                  className="mr-2 rounded-md"
                  onChange={() => setUpdateEmail(!updateEmail)}
                />
                <label className="flex text-start text-[#757575]">
                  I would like to get product update emails.
                </label>
              </div>
            </div> */}
            <div className="w-full flex items-center gap-1 py-6">
              <hr className="w-[50%] border-[#cacaca]" />
              <p>OR</p>
              <hr className="w-[50%] border-[#cacaca]" />
            </div>
          </div>
        ) : (
          <h2 className="text-left text-4xl mb-4 font-bold text-storyblue">
            {promoPlan ? "Sign Up" : "Purchase Plan"}
          </h2>
        )}
        <Formik
          initialValues={signUpInitailValues}
          validationSchema={signupViaGoogle ? SignUpViaGoogleSchema() : SignUpSchema(signUpStep)}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ errors, touched, values, handleChange, handleSubmit, setFieldValue, isValid, dirty }) => (
            <form
              id="form-signup"
              name="form-signup"
              className="text-start flex flex-col gap-3"
              onSubmit={handleSubmit}
            >
              {signUpStep == 1 ? (
                <>
                  <div className="w-full flex justify-between flex-wrap">
                    <div>
                      <label className="font-medium">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full rounded-md"
                        autoFocus
                      />
                      <div className="text-red-600 mt-1">{touched?.firstName && errors?.firstName}</div>
                    </div>
                    <div>
                      <label className="font-medium">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full rounded-md"
                      />
                      <div className="text-red-600 mt-1">{touched?.lastName && errors?.lastName}</div>
                    </div>
                  </div>
                  <div>
                    <label className="font-medium">E-Mail Address</label>
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full rounded-md"
                    />
                    <div className="text-red-600 mt-1">{touched.email && errors.email}</div>
                  </div>
                  <div>
                    <label className="font-medium">Password</label>
                    <div className="relative mt-1">
                      <input
                        type={showSignupPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        className="p-2 w-full rounded-md pr-10"
                      />
                      <span
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={() => setShowSignupPassword(!showSignupPassword)}
                      >
                        <FontAwesomeIcon
                          icon={showSignupPassword ? faEye : faEyeSlash}
                          className="text-gray-500 hover:text-gray-700"
                        />
                      </span>
                    </div>
                    <div className="text-red-600 mt-1">{touched.password && errors.password}</div>
                  </div>
                  <div>
                    <label className="font-medium">Confirm Password</label>
                    <div className="relative mt-1">
                      <input
                        type={showSignupConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full rounded-md"
                      />
                      <span
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={() => setShowSignupConfirmPassword(!showSignupConfirmPassword)}
                      >
                        <FontAwesomeIcon
                          icon={showSignupConfirmPassword ? faEye : faEyeSlash}
                          className="text-gray-500 hover:text-gray-700"
                        />
                      </span>
                    </div>
                    {touched.confirmPassword && errors.confirmPassword ? (
                      <div className="text-red-600 mt-1">{errors.confirmPassword}</div>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div>
                      <div className="flex items-baseline">
                        <input
                          type="checkbox"
                          name="isAdult"
                          checked={values.isAdult}
                          onChange={handleChange}
                          className="mr-2 rounded-md"
                        />
                        <label className="flex text-start text-[#757575]">
                          I am over 18 or have adult supervision.
                        </label>
                      </div>
                      <div className="text-red-600 mt-1 ml-5">{touched.isAdult && errors.isAdult}</div>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-12 flex-wrap">
                    <button
                      className="bg-storyblue text-white font-bold rounded-md text-base w-60 h-11 items-center"
                      onClick={() => {
                        if (isValid && dirty) {
                          setSignUpStep(2);
                          updateUserEmail(values.email);
                        } else {
                          Toast("Please fill in all required fields and make sure the form is valid.", true);
                        }
                      }}
                    >
                      Sign Up
                    </button>
                    <h1 className="text-base md:text-lg text-storyblue">
                      <strong>
                        <Link
                          className="text-storygreen"
                          to="#"
                          onClick={() => {
                            setShowLogin(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Login
                        </Link>
                      </strong>{" "}
                      to continue.
                    </h1>
                  </div>
                </>
              ) : (
                <>
                  {!promoPlan && (
                    <>
                      <div className="flex flex-col gap-2 text-base font-medium text-storyblue">
                        <div className="flex justify-start items-start gap-4">
                          <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
                          <h1>
                            Access to "Book Builder" craft personalized children's books for your loved one.
                          </h1>
                        </div>
                        <div className="flex justify-start items-start gap-4">
                          <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
                          <h1>Unlimited Story Editing.</h1>
                        </div>
                        <div className="flex justify-start items-start gap-4">
                          <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
                          <h1>Unlimited PDFs Generated.</h1>
                        </div>
                      </div>
                      <div className="w-full flex justify-between ">
                        <div className="w-full flex flex-col gap-1">
                          <select
                            placeholder="Select Plan"
                            name="plan"
                            id="plan"
                            value={selectedPlan}
                            className="w-[100%] h-10 rounded-md "
                            onChange={(e) => {
                              setFieldValue("plan", e.target.value);
                              handlePlanChange(e);
                            }}
                          >
                            <option value={""} disabled>
                              Select Plan
                            </option>
                            {plans?.map((plan, index) => (
                              <option key={index} value={plan.id} className="capitalize">
                                {plan.name} - $ {parseFloat(plan.cost).toFixed(2)} /{" "}
                                <span className="capitalize">
                                  {capitalizeFirstCharacter(replaceFromString(plan?.chargeCycle, "_-"))}
                                </span>
                                {/* {plan?.chargeCycle == "one_time" ? "Once" : "/Month"} */}
                              </option>
                            ))}
                          </select>
                          {/* {selectedPlanDescription && (
                        <div className="bg-white rounded-md border-2 border-storylightgreen flex flex-col gap-1 justify-around items-start w-[100%] my-2 p-2">
                          <h1 className="text-md text-storyblue">
                            <strong>Image Credits:</strong>{" "}
                            {parseInt(selectedPlanDescription?.credits) *
                              parseInt(selectedPlanDescription?.storyCreationLimit)}
                          </h1>
                          <h1 className="text-md text-storyblue">
                            <strong> Additional Image credits: </strong>
                            {selectedPlanDescription?.additionalAICredit?.credits} Credits for ${" "}
                            {selectedPlanDescription?.additionalAICredit?.price}
                          </h1>

                          {/* {selectedPlanDescription.unlimitedDownloads && (
                            <h1 className="text-md text-storyblue">
                              <strong>Unlimited Downloads</strong>
                            </h1>
                          )} 
                        </div>
                      )} */}
                          {selectedPlanDescription && (
                            <div className="bg-white rounded-md border-2 border-storylightgreen flex flex-col gap-1 justify-around items-start w-full my-2 p-2">
                              {/* <h1 className="text-md text-storyblue">
                                <strong> Monthly Stories: </strong>{" "}
                                {selectedPlanDescription?.storyCreationLimit}
                              </h1> */}

                              {selectedPlanDescription?.freePhysicalBookStatus === true &&
                              selectedPlanDescription?.freePhysicalBookQuantity ? (
                                <h1 className="text-md text-storyblue">
                                  <strong> Free Physical Book(s): </strong>
                                  {selectedPlanDescription?.freePhysicalBookQuantity}
                                </h1>
                              ) : (
                                <h1 className="text-md text-storyblue">
                                  <strong> Monthly Stories: </strong>{" "}
                                  {selectedPlanDescription?.storyCreationLimit}
                                </h1>
                              )}

                              <h1 className="text-md text-storyblue capitalize">
                                <strong> Cost: </strong> $ {selectedPlanDescription?.cost}
                                {" / "}
                                {selectedPlanDescription?.chargeCycle?.replaceAll("_", " ")}
                              </h1>
                              <span className="capitalize whitespace-pre-wrap">
                                {selectedPlanDescription?.mics || ""}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between gap-2 md:gap-4 mt-3 rounded-md h-10">
                        <input
                          type="text"
                          name="promoCode"
                          // maxLength={10}
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                          placeholder={placeholder}
                          className={`p-2 w-[50%] rounded-md uppercase ${couponClass}`}
                        />
                        <button
                          type="button"
                          className={`${
                            !couponCode ? "pointer-events-none opacity-70" : ""
                          } w-52 flex justify-center items-center gap-1 whitespace-nowrap bg-storygreen text-white font-bold rounded-md text-base h-10`}
                          // className="w-52 flex justify-center items-center gap-1 whitespace-nowrap bg-storygreen text-white font-bold rounded-md text-base h-10"
                          onClick={() => handleApplyPromoCode()}
                          disabled={!selectedPlanDescription || couponCode?.length < 1}
                        >
                          Apply Coupon / Gift Card {couponLoader && <Loader loader={couponLoader} />}
                        </button>
                      </div>

                      {selectedPlanDescription && (
                        <p className="flex pt-2 text-storygreen text-sm">
                          {" "}
                          {couponDiscount && (
                            <span className=" text-storygreen text-sm">
                              {" "}
                              Discount applied of{" "}
                              <b className="pl-1">
                                {" "}
                                ${" "}
                                {parseFloat(couponDiscount) > parseFloat(selectedPlanDescription?.cost)
                                  ? selectedPlanDescription?.cost
                                  : couponDiscount}
                              </b>
                              .
                            </span>
                          )}
                          {trialDays ? (
                            <span className="w-full sm:w-auto">
                              After&nbsp;<strong>{trialDays} trial days</strong>,{" "}
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="w-full sm:w-auto">
                            You will be charged <b className="pl-1"> ${price}</b>
                          </span>
                        </p>
                      )}

                      {couponDiscount && couponDescription?.length > 0 && (
                        <div className="flex pt-0">
                          <span className=" text-storygreen text-sm">
                            <strong> Description: </strong> {couponDescription}
                          </span>
                        </div>
                      )}

                      {selectedPlanDescription && (
                        <div className="mt-3 bg-white p-2 rounded-md h-10">
                          <CheckoutForm />
                        </div>
                      )}
                    </>
                  )}
                  <div>
                    {/* <div className="flex items-baseline">
                      <input
                        type="checkbox"
                        name="terms"
                        checked={values.terms}
                        onChange={handleChange}
                        className="mr-2 rounded-md"
                      />
                      <label className=" flex text-start text-[#757575] text-base">
                        By clicking here, I confirm that I have read and agree to the
                        <a to="https://buildmystory.ai/terms-and-conditions/" target="_blank">
                          terms and conditions
                        </a>
                        provided in the links below.
                      </label>
                    </div> */}

                    <div className="flex items-start">
                      <input
                        type="checkbox"
                        name="terms"
                        checked={values.terms}
                        onChange={handleChange}
                        className="mr-2 mt-1 h-5 w-5 rounded border-gray-300 focus:ring-0"
                      />
                      <label className="text-base text-gray-600">
                        By clicking here, I confirm that I have read and agree to the{" "}
                        <a
                          href="https://buildmystory.com/terms-and-conditions/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-black underline"
                        >
                          terms and conditions
                        </a>{" "}
                        provided in the links below.
                      </label>
                    </div>

                    <div className="text-red-600 mt-1 ml-5">{touched.terms && errors.terms}</div>
                  </div>

                  {/* <div>
                    <div className="flex items-baseline">
                      <input
                        type="checkbox"
                        name="terms"
                        checked={values.terms}
                        onChange={handleChange}
                        className="mr-2 rounded-md"
                      />
                      <label className=" flex text-start text-[#757575] text-base">
                        <Link to="https://buildmystory.ai/terms-and-conditions/" target="_blank">
                          By clicking{" "}
                          <strong className="font-black underline md:underline italic">here</strong>, I state
                          that I have read and agree to the terms and conditions.
                        </Link>
                      </label>
                    </div>
                    <div className="text-red-600 mt-1 ml-5">{touched.terms && errors.terms}</div>
                  </div> */}

                  <div>
                    <div className="flex items-baseline">
                      <input
                        type="checkbox"
                        name="promotionalEmail"
                        checked={values.promotionalEmail}
                        onChange={handleChange}
                        className="mr-2 rounded-md"
                        title="Wants to Get Promotional Emails?"
                      />
                      <label className="flex text-start text-[#757575]">
                        {/* I would like to receive promotional emails. */}
                        Join Our Community! Receive parenting tips, positive stories, community news, and
                        special offers.
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-baseline">
                      <input
                        type="checkbox"
                        name="updateEmail"
                        checked={values.updateEmail}
                        onChange={handleChange}
                        className="mr-2 rounded-md"
                        title="Wants to Get Product Updates?"
                      />
                      <label className="flex text-start text-[#757575]">
                        I would like to get product update emails.
                      </label>
                    </div>
                  </div>

                  <SubmitButton
                    title={plan === PROMO_PLAN ? "Continue" : "Subscribe"}
                    loader={signupLoader}
                    size="w-[240px]"
                    marginTop="mt-[0px]"
                    // disable={(parseInt(price) < 1 ? true : false) }
                  />
                </>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SignUp;
