import { LOCAL_STORAGE_TOKEN } from "../helper/Constant";

const callAPI = async (
  endPoint,
  data = null,
  method = "POST",
  headers = { "Content-Type": "application/json" }
) => {
  try {
    let postRequestMeta = { headers: { ...headers } };

    if (data) {
      postRequestMeta = {
        method,
        headers,
        body: JSON.stringify(data),
      };
    }
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    if (token) postRequestMeta.headers.Authorization = `Bearer ${token}`;
    // console.log("api call", endPoint, data,headers);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${endPoint}`, postRequestMeta);
    // const response = await fetch(`https://backend.buildmystory.ai/${endPoint}`, postRequestMeta);

    const responseData = await response.json();
    // console.log("responseDataresponseData", responseData);
    if (responseData?.error === "Token Expired") {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN);
      window.location.reload();
      return;
    }
    return responseData;
  } catch (err) {
    console.log("Internal error:", err);
    return null;
  }
};
export { callAPI };
