import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/Common/Button";
import LowCreditModal from "../../components/LowCreditModal/LowCreditModal";
import { faFileLines, faGrip, faPen } from "@fortawesome/free-solid-svg-icons";
import PageModal from "../../components/PageModal/PageModal";
import StoryPages from "./StoryPages";
import ChooseCover from "./ChooseCover";
import { useEffect, useState } from "react";
import { callAPI } from "../../services/apiService";
import { LOCAL_STORAGE_EDIT_MODE, 
  // STORY_EDITOR_VEDIO, STORY_EDITOR_VIDEO 
} from "../../helper/Constant";
import { useStory } from "../../context/storyContext";
import Guide from "../../components/Common/Guide";
import { useApp } from "../../context/appContext";
import { useUser } from "../../context/userContext";
import TrainingPopup from "../trainingvideos/TrainingPopup";

const StepStoryBuilder = ({
  setLoadingStatus,
  // setUserCreditBalance,
  setStateData,
  setShowImageHistory,
  setShowLowCreditModal,
  setCurrentStep,
  setBookTitle,
  // setStoryPages,
  setMode,
  setModalOpen,
  setModalMode,
  setShowBookCover,
  setSelectedCoverUrl,
  showLowCreditModal,
  showImageHistory,
  showBookCover,
  showStoryForm,
  stateData,
  bookId,
  bookData,
  bookTitle,
  imagesGenerated,
  selectedCoverUrl,
  loader,
  loaderImageGeneration,
  startStepSix,
  currentStep,
  draftId,
  mode,
  modalMode,
  modalOpen,
  // userCreditBalance,
  resetStory,
  displayBookPages,
  summaryModal,
  setSummaryModal,
  setBookDataModified,
  setShowStoryForm,
  setimageCharacterPrompt,
  imageCharacterPrompt,
  setShowCharacterLibrary,
}) => {
  const [editing, setEditing] = useState({
    isPortraitDeleted: false,
    bookId: null,
    pageIndex: null,
    pageData: null,
    type: null,
    index: null,
  });
  const { isTitleModalOpen, setIsTitleModalOpen } = useApp();
  const { storyData } = useStory();
  const { user, trainingVideos } = useUser();
  const [showEditTextIllustration, setShowEditTextIllustration] = useState(false);
  // const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const editMode = localStorage.getItem(LOCAL_STORAGE_EDIT_MODE);

  const closeTitleModal = () => {
    setIsTitleModalOpen(false);
    // console.log('istitle changed', false)
  };
  const openTitleModal = () => {
    setIsTitleModalOpen(true);
    // console.log("istitle changed", true);
  };

  const openModal = (pageIndex, pageData, modalMode, index = 0) => {
    setSelectedPage({ ...pageData, pageIndex, index });
    setModalOpen(true);
    setModalMode(modalMode);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPage(null);
  };

  const updateTitle = async (newTitle) => {
    try {
      await callAPI("api/update-book-title", { bookId, newTitle });

      if (editMode === "yes") setBookDataModified(true);

      setBookTitle(newTitle);
      displayBookPages();
    } catch (error) {
      console.error("Error updating book title:", error);
    }
  };

  // const updatePageData = async (pageIndex, updatedText, updatedScene) => {
  //   // setStoryPages((prevPages) => ({
  //   //   ...prevPages,
  //   //   [pageIndex]: { ...prevPages[pageIndex], text: updatedText, scene: updatedScene },
  //   // })
  // // );

  //   try {
  //     await callAPI("api/update-book-text", {
  //       bookId,
  //       pageIndex,
  //       updatedText,
  //       updatedScene,
  //     });

  //     if (editMode === "yes") setBookDataModified(true);
  //     displayBookPages();
  //   } catch (error) {
  //     console.error("Error updating book text:", error);
  //   }
  // };

  function TitleModal({ title, onSave, onClose }) {
    const [newTitle, setNewTitle] = useState(title);

    const handleSaveTitle = () => {
      onSave(newTitle);
      onClose();
    };

    return (
      <div className={`modal ${isTitleModalOpen ? "block" : "hidden"}`}>
        <div className="p-3">
          <input
            autoFocus
            type="text"
            value={newTitle}
            maxLength={30}
            onChange={(e) => setNewTitle(e.target.value)}
            className="input-field w-[100%] p-2 text-center"
          />
          <div className="flex justify-center gap-2">
            <button
              onClick={handleSaveTitle}
              className="bg-storyblue text-white font-bold font-sans rounded-md p-2 text-xs mt-4"
            >
              Save
            </button>
            <button
              onClick={onClose}
              className="bg-storygreen text-white font-bold font-sans rounded-md p-2 text-xs mt-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
  // console.log("currrent", currentStep);

  //section for training videosssssss
  const [showPopUp, setshowPopUp] = useState(true);

  // Show popup after a delay
  // useEffect(() => {
  //   const popupTimer = setTimeout(() => {
  //     setshowPopUp(true);
  //   }, 3000);

  //   return () => clearTimeout(popupTimer);
  // }, []);

  const onClose = async () => {
    try {
      await callAPI("user/updateUser", {
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        settings: user?.settings,
        screentoUpdate: "screen3",
      });
    } catch (error) {
      console.log("error updating user training screen", error.message);
    } finally {
      setshowPopUp(false);
    }
  };

  // console.log("STORY_EDITOR_VIDEO ", trainingVideos?.STORY_EDITOR_VIDEO);

  return (
    <>
      {showLowCreditModal ? (
        <LowCreditModal
          showLowCreditModal={showLowCreditModal}
          setShowLowCreditModal={setShowLowCreditModal}
        />
      ) : (
        <div className="text-left p-4 mb-8 text-sm text-storyblue animate__animated animate__slideInRight">
          {showPopUp === true && user?.showTrainingPopup["screen3"] === true && (
            <TrainingPopup
              videoUrl={currentStep === 3 ? trainingVideos?.STORY_EDITOR_VIDEO : null}
              onClose={onClose}
              showScreenName={"screen3"}
              user={user}
              focus={showPopUp}
            />
          )}
          <div className=" w-full flex justify-between mb-2 flex-wrap">
            {/* <h2 className="text-left text-2xl  md:text-4xl mb-4 font-bold text-storyblue">
              {showImageHistory
                ? "Image Library"
                : showBookCover
                ? "Book Cover"
                : showEditTextIllustration && editing?.type == "editPrompt"
                ? "Build My Illustration"
                : "Build My Story"}
            </h2> */}

            <div className=" flex items-center align-end mb-4">
              <h2 className="text-left text-2xl  md:text-4xl  font-bold text-storyblue">
                {showImageHistory
                  ? "Image Library"
                  : showBookCover
                  ? "Book Cover"
                  : showEditTextIllustration && editing?.type === "editPrompt"
                  ? "Build My Illustration"
                  : "Build My Story"}
              </h2>
              <div className="mt-1">
                <Guide/>
                {/* <Guide
                  to={currentStep === 3 ? trainingVideos?.STORY_EDITOR_VIDEO : null}
                  // /////
                  screen={"screen3"}
                  user={user}
                /> */}
              </div>
            </div>

            {/* {!showBookCover && ( */}
            <div className="flex flex-row flex-wrap gap-2">
              {stateData?.showDownloadPDF && !summaryModal && (
                <>
                  <Button
                    title={`${
                      user?.plan?.freePhysicalBookStatus === true &&
                      parseInt(user?.freePhysicalBookCreated ? user?.freePhysicalBookCreated : 0) <
                        parseInt(user?.freePhysicalBookQuantity)
                        ? "Flip Book / Redeem Book"
                        : "Flip Book / Purchase Book"
                    }`}
                    tooltip={imagesGenerated ? "Flip Book" : "Download PDF Once All Images Generated"}
                    disabled={!imagesGenerated}
                    loader={loader}
                    onclick={startStepSix}
                    className={`submit-button px-4 bg-storygreen text-white font-bold rounded-md text-sm  h-11 ${
                      imagesGenerated ? "" : "opacity-50"
                    }`}
                  />

                  {/* <button
                    className="px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                      setCurrentStoryStep(STORY_STEP_CHARACTER_BUILD);
                    }}
                  >
                  
                    Back
                  </button> */}
                </>
              )}
            </div>
          </div>
          <p
            className={`mb-6 text-left text-sm  ${
              showEditTextIllustration && editing?.type === "editPrompt" ? "text-red-600" : "text-storyblue"
            }`}
          >
            {showImageHistory
              ? "The Image Library stores images that were created for your story that are no longer being used in the story you are creating.  This will allow you to revert back to a previous image or use an image on a different page"
              : showEditTextIllustration && editing?.type === "editPrompt"
              ? `You can modify your illustration with an existing character or add a new character to your story by 
              editing the prompt text and/or selecting "Select New Character" for the illustration. Then select "New Image".`
              : `We're excited to build your personalized childrens book's storyline and illustrations. Our
                    Builder will create your story and illustrations that will be available for review
                    shortly. We'll showcase the story for your review, and the illustrations will be revealed
                    as soon as our Builder completes each one.`}

            {/* {showEditTextIllustration && editing?.type === "editPrompt" && (
              <div className="mt-4 text-center font-bold">
                Feel free to edit the text in the prompt and or "Select a New Character" for the illustration.
                then select "New Image"
              </div>
            )} */}
          </p>
          {showStoryForm && !showBookCover && currentStep === 3 && (
            <div className="hidden md:flex justify-center gap-4 rounded bg-white w-full p-2 my-3  lg:hidden">
              <div className="cursor-pointer" onClick={() => setMode("Grid")}>
                <FontAwesomeIcon
                  icon={faGrip}
                  className=" text-3xl text-storyblue"
                  title="Switch to Page Mode"
                />
                <p className="text-storyblue">Grid</p>
              </div>
              <div className="cursor-pointer" onClick={() => setMode("Page")}>
                <FontAwesomeIcon
                  icon={faFileLines}
                  className=" text-3xl text-storyblue"
                  title="Switch to Page Mode"
                />
                <p className="text-storyblue">Page</p>
              </div>
            </div>
          )}
          {(!bookData || !bookData?.pages) && !storyData?.storybuildingError && (
            <div>
              <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img className="w-24 h-24 mb-3" src="/loading.gif" alt="Loading" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Loading book and generating images ...</span>
                  </p>
                </div>
              </label>
            </div>
          )}
          {bookData && bookData?.pages && (
            <div className="max-w-8xl py-0 mx-auto animate__animated animate__backInUp">
              {!showBookCover && !showEditTextIllustration && (
                <div className="w-full bg-white rounded-xl p-4 mb-4">
                  <h2 className="text-2xl md:text-4xl font-serif text-center font-bold cursor-pointer">
                    {bookTitle}{" "}
                    <span>
                      <FontAwesomeIcon
                        icon={faPen}
                        onClick={openTitleModal}
                        className="text-storygreen text-xl"
                      />
                    </span>
                  </h2>
                  {isTitleModalOpen && (
                    <TitleModal title={bookTitle} onSave={updateTitle} onClose={closeTitleModal} />
                  )}
                  <p className="text-xs text-storyblue text-center">BOOK ID: {bookId}</p>
                </div>
              )}
              {showBookCover ? (
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  <ChooseCover
                    bookData={bookData}
                    setShowBookCover={setShowBookCover}
                    showBookCover={showBookCover}
                    setSelectedCoverUrl={setSelectedCoverUrl}
                    selectedCoverUrl={selectedCoverUrl}
                    displayBookPages={displayBookPages}
                    setStateData={setStateData}
                    stateData={stateData}
                  />
                </div>
              ) : (
                <>
                  <StoryPages
                    bookData={bookData}
                    openModal={openModal}
                    edit={true}
                    selectedPage={selectedPage}
                    loaderImageGeneration={loaderImageGeneration}
                    setShowImageHistory={setShowImageHistory}
                    showImageHistory={showImageHistory}
                    mode={mode}
                    // userCreditBalance={userCreditBalance}
                    // setUserCreditBalance={setUserCreditBalance}
                    setShowLowCreditModal={setShowLowCreditModal}
                    setShowBookCover={setShowBookCover}
                    showBookCover={showBookCover}
                    selectedCoverUrl={selectedCoverUrl}
                    displayBookPages={displayBookPages}
                    setStateData={setStateData}
                    stateData={stateData}
                    summaryModal={summaryModal}
                    setSummaryModal={setSummaryModal}
                    setShowStoryForm={setShowStoryForm}
                    setCurrentStep={setCurrentStep}
                    setimageCharacterPrompt={setimageCharacterPrompt}
                    imageCharacterPrompt={imageCharacterPrompt}
                    showEditTextIllustration={showEditTextIllustration}
                    setShowEditTextIllustration={setShowEditTextIllustration}
                    editing={editing}
                    setEditing={setEditing}
                    setShowCharacterLibrary={setShowCharacterLibrary}
                  />
                </>
              )}
              {/* Modal for editing page data */}
              <PageModal
                bookId={bookId}
                pageData={selectedPage}
                // onSave={updatePageData}
                onClose={() => closeModal()}
                modalMode={modalMode}
                modalOpen={modalOpen}
                displayBookPages={displayBookPages}
                setShowLowCreditModal={setShowLowCreditModal}
                setLoadingStatus={setLoadingStatus}
                // userCreditBalance={userCreditBalance}
                // setUserCreditBalance={setUserCreditBalance}
              />
            </div>
          )}
          {stateData?.showDownloadPDF && !summaryModal && (
            <div className="text-center mt-8 w-full flex justify-center ">
              <Button
                disabled={!imagesGenerated}
                tooltip={imagesGenerated ? "Flip Book" : "Download PDF Once All Images Generated"}
                // title="Flip Book / Purchase Book"
                title={`${
                  user?.plan?.freePhysicalBookStatus === true &&
                  parseInt(user?.freePhysicalBookCreated ? user?.freePhysicalBookCreated : 0) <
                    parseInt(user?.freePhysicalBookQuantity)
                    ? "Flip Book / Redeem Book"
                    : "Flip Book / Purchase Book"
                }`}
                loader={loader}
                onclick={startStepSix}
                // className={`submit-button bg-storyblue text-white font-bold rounded-md text-base h-11 items-center w-[64vw] mb-2 ${
                className={`submit-button bg-storyblue text-white font-medium md:font-bold rounded-md text-sm md:text-base h-11 items-center w-[64vw] mb-2 ${
                  imagesGenerated ? "" : "opacity-50"
                }`}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StepStoryBuilder;
