import React, { useState, useEffect } from "react";
import "animate.css";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import StepsBar from "../../components/StepsBar/StepsBar";
import "../../App.css";

import { Toast } from "../../components/Common/Toast";
import { useUser } from "../../context/userContext";
import { Navigate, useParams, useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { callAPI } from "../../services/apiService";
import { useApp } from "../../context/appContext";
import {
  CHARACTER_AGE_UNIT,
  CHARACTER_TYPE_HUMAN,
  ERROR_MODULE_CHARACTER_GENERATION,
  ERROR_MODULE_IMAGE_GENERATION,
  LOCAL_STORAGE_BOOK_ID,
  LOCAL_STORAGE_EDIT_MODE,
  LOCAL_STORAGE_STORY_STEP,
  PROMO_PLAN,
  STORY_LANGUAGE_ENGLISH,
  STORY_STEP_BASIC_INFO,
  STORY_STEP_CHARACTER_BUILD,
  STORY_STEP_ILLUSTRATION_CREATE,
  STORY_STEP_PURCHASE_DOWNLOAD,
  USER_STATUS_ACTIVE,
} from "../../helper/Constant";

import {
  checkSubscribedToPlan,
  doStartImageGeneration,
  fetchBookData,
  getCharacterImage,
  getDraftDetails,
  // getPlans,
  getstoryConfig,
  sortPages,
  getCharacterLibary,
  storyExample,
  checkUserBookCreationStatus,
  checkSingleBookPlan,
  getLatestError,
} from "./builderHelpers";
import Footer from "../../components/Footer/Footer";
import { goToTopOfPage, setCurrentStoryStep } from "../../helper/helper";
import SignUp from "./SignUp";
import StepPDFDownloadPurchase from "./StepPDFDownloadPurchase";
import StepStoryBuilder from "./StepStoryBuilder";
import StepStoryInformation from "./StepStoryInformation";
import StepCharacterBuilder from "./StepCharacterBuilder";
import Login from "./Login";
import StepKickStart from "./StepKickStart";
import AccountVerfication from "./AccountVerification";
import ToolBar from "./ToolBar";
import { useStory } from "../../context/storyContext";
import Modal from "../../components/Common/Modal";
import PlanPurchase from "../../components/Common/PlanPurchase";
import ThankYou from "../../components/Common/ThankYou";

function Builder({ onNavigate }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramPlan = searchParams.get("plan");

  const navigateTo = useNavigate();
  const { user, showLogin, setShowLogin, setSingleBookPlan } =
    useUser();
  const {
    draftId,
    setDraftId,
    currentStep,
    setCurrentStep,
    setStartNewStory,
    bookId,
    setBookId,
    bookData,
    setBookData,
    setBookDataModified,
    isAdmin,
    isTitleModalOpen,
    setIsTitleModalOpen,
  } = useApp();

  const { setStoryNewCharacter, storyData, setStoryData } = useStory();

  const { newBook } = useParams();
  const [editMode, setEditMode] = useState("no");
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [artStyle, setArtStyle] = useState("");

  const [childName, setChildName] = useState("");
  const [childAge, setChildAge] = useState("");
  const [wordRange, setWordRange] = useState("");
  const [storyTopic, setStoryTopic] = useState("");
  const [storyType, setStoryType] = useState(""); // Set default value
  // const [storyPages, setStoryPages] = useState({});
  const [writingStyle, setWritingStyle] = useState(""); // Set default value
  const [pageTotal, setPageTotal] = useState(""); // Set default value

  const [selectedArtStyle, setSelectedArtStyle] = useState(null); // Set default value

  const [showStoryForm, setShowStoryForm] = useState(false);
  const [imageGenerationStarted, setImageGenerationStarted] = useState(false);
  const [bookTitle, setBookTitle] = useState("");
  // const [bookSummary, setBookSummary] = useState("");
  // const [plans, setPlans] = useState([]);
  const [storyconfig, setStoryConfig] = useState({});
  const [selectedStoryConfig, setSelectedStoryConfig] = useState(null);
  const [maxPages, setMaxpages] = useState(52);
  const [pageOptions, setPageOptions] = useState([]);
  const [storyLanguage, setStoryLanguage] = useState(STORY_LANGUAGE_ENGLISH);
  const [planSubscribed, setPlanSubscribed] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // New state for the image file
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [characterOutlineByUser, setCharacterOutlineByUser] = useState({
    characterType: CHARACTER_TYPE_HUMAN,
    ageUnit: CHARACTER_AGE_UNIT,
    // mics: "",
  });
  const [characterDescription, setCharacterDescription] = useState("");
  const [currentPortrait, setCurrentPortrait] = useState(null);
  const [currentPortraitList, setCurrentPortraitList] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [showCharacterLibrary, setShowCharacterLibrary] = useState(true); //javi
  const [library, setLibrary] = useState([]);
  const [showFileInput, setShowFileInput] = useState(true);
  const [loader, setLoader] = useState(false);
  const [imagesGenerated, setImagesGenerated] = useState(false);
  const [loaderImageGeneration, setLoaderImageGeneration] = useState([]);

  const [showBookCover, setShowBookCover] = useState(false);
  const [showImageHistory, setShowImageHistory] = useState(null);
  const [showLowCreditModal, setShowLowCreditModal] = useState(false);
  const [characterGenerationProgress, setCharacterGenerationProgress] = useState(10);
  const [summaryModal, setSummaryModal] = useState(false);

  const [imageCharacterPrompt, setimageCharacterPrompt] = useState();

  const [showThanksNote, setShowThanksNote] = useState(false);

  // const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);

  const [signUpStep, setSignUpStep] = useState(1);

  const { setUser } = useUser();

  const [loaders, setLoaders] = useState({
    characterGenerating: false,
    characterRegenerate: false,
    planExist: false,
  });

  const [stateData, setStateData] = useState({
    characterId: null,
    showDownloadPDF: true,
    showImageLibrary: false,
    pageIndex: null,
    purchasePlan: false,
  });

  const [mode, setMode] = useState("Grid");
  const [startInterval, setStartInterval] = useState(false);

  useEffect(() => {
    if (signUpStep === 2) {
      setTimeout(() => setUser({email: user?.email}), 250);
    }
  }, [signUpStep]);

  useEffect(() => {
    if (paramPlan) setShowLogin(false);
  }, [paramPlan]);

  // console.log("STORYDATA credits", storyData?.creditsPerBookLimit, bookData?.creditsPerBookLimit);
  useEffect(() => {
    if (bookId) displayBookPages();
  }, [bookId]);

  useEffect(() => {
    let errorModule = null;
    if (currentStep === 2) errorModule = ERROR_MODULE_CHARACTER_GENERATION;
    else errorModule = ERROR_MODULE_IMAGE_GENERATION;

    if ((bookData?.error || bookData?.progress >= 10) && currentStep === 2) {
      if (bookData?.illustrationCharacter?.characterPortrait) {
        // console.log("HITTTTTTT", bookData);
        // setCurrentPortrait(bookData?.illustrationCharacter?.characterPortrait);
        // setCurrentPortraitList(bookData?.illustrationCharacter?.currentCharacterPortraitList);
      } else {
        getCharacterImage(
          bookId,
          setLoaders,
          loaders,
          setCurrentPortrait,
          setCharacterOutlineByUser,
          setCurrentPortraitList,
          setCharacterGenerationProgress,
          setStartInterval,
          bookData,
          setStoryData,
          storyData,
          errorModule
        );
      }
    } else if (currentStep === 3 && storyData?.gettingError === false && isTitleModalOpen === false) {
      getLatestError(bookData?.bookId, errorModule, setStoryData);
    }

    setStoryData((prevData) => ({
      ...prevData,
      storybuildingError: false,
    }));
    // else if(bookData?.error) {

    // }
  }, [currentStep, bookData]);

  useEffect(() => {
    if (showCharacterLibrary) getCharacterLibary(user?.email, loaders, setLoaders, setLibrary);
  }, [showCharacterLibrary, user?.email]);
  const storyPreview = "/avatar-icon.png";
  // const [userCreditBalance, setUserCreditBalance] = useState(0);
  const [showDescription, setShowDescription] = useState(false);

  // useEffect(() => {
  //   setUserCreditBalance(user?.creditBalance || 0);
  // }, [user?.creditBalance]);

  const resetCharacter = () => {
    setSelectedImage(null);
    setCurrentPortrait(null);
    setCurrentPortraitList([]);
    setimageCharacterPrompt(null);
    setShowCharacterLibrary(false);
    setStoryNewCharacter(true);
    setStoryData((prevData) => ({
      ...prevData,
      showLibrary: false,
    }));
  };

  const resetStory = () => {
    localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
    localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
    setSelectedImage(null);
    setDraftId(null);
    setBookId(null);
    setUploadedImageUrl("");
    setCharacterDescription("");
    // setStoryPages({});
    setBookTitle("");
    // setBookSummary("");
    setBookData(null);

    setChildName("");
    setChildAge("");
    setStoryTopic("");
    setStoryType("");
    setPageTotal("");
    setWritingStyle("");
    setArtStyle("");
    setStoryLanguage("english");
    setWordRange("");
    setCurrentStep(0);
    setShowStoryForm(false);
    setStartNewStory(true);
    setSelectedArtStyle(null);
    setCharacterOutlineByUser({ characterType: CHARACTER_TYPE_HUMAN, ageUnit: CHARACTER_AGE_UNIT });
    setCurrentPortrait(null);
    setCurrentPortraitList([]);
  };

  useEffect(() => {
    // when user hit create New Book, then clear every thing
    if (newBook) resetStory();
  }, [newBook]);

  useEffect(() => {
    const isEditMode = localStorage.getItem(LOCAL_STORAGE_EDIT_MODE);
    setEditMode(isEditMode ? isEditMode : "no");

    let bookId = localStorage.getItem(LOCAL_STORAGE_BOOK_ID);

    if (draftId || editMode === "yes") {
      getDraftDetails(
        draftId || bookId,
        setBookId,
        setCurrentStep,
        setShowStoryForm,
        setUploadedImageUrl,
        setCharacterDescription,
        // setStoryPages,
        setBookTitle,
        // setBookSummary,
        setStoryType,
        setChildName,
        setChildAge,
        setMaxpages,
        setPageTotal,
        setWritingStyle,
        setSelectedArtStyle,
        setStoryLanguage,
        setWordRange,
        setCurrentPortrait,
        setCurrentPortraitList,
        setCharacterOutlineByUser,
        setSelectedImage,
        setSelectedStoryConfig,
        sortPages,
        setBookData,
        storyconfig,
        setStoryTopic,
        setCurrentStoryStep,
        setStoryData
      );

      // what if step=1

      // setCurrentStep(3);
      if (draftId || bookId) {
        setSelectedBookId(draftId || bookId);
        localStorage.setItem(LOCAL_STORAGE_BOOK_ID, draftId || bookId);
        localStorage.setItem(LOCAL_STORAGE_EDIT_MODE, "yes");
        setEditMode("yes");
      }
    } else {
      if (user?.email && bookId) {
        setSelectedBookId(bookId);
        getDraftDetails(
          draftId || bookId,
          setBookId,
          setCurrentStep,
          setShowStoryForm,
          setUploadedImageUrl,
          setCharacterDescription,
          // setStoryPages,
          setBookTitle,
          // setBookSummary,
          setStoryType,
          setChildName,
          setChildAge,
          setMaxpages,
          setPageTotal,
          setWritingStyle,
          setSelectedArtStyle,
          setStoryLanguage,
          setWordRange,
          setCurrentPortrait,
          setCurrentPortraitList,
          setCharacterOutlineByUser,
          setSelectedImage,
          setSelectedStoryConfig,
          sortPages,
          setBookData,
          storyconfig,
          setStoryTopic,
          setCurrentStoryStep,
          setStoryData
        );
      } else {
        // setShowStoryForm(true)
        setCurrentStep(0);
        localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
      }
    }
  }, []);

  useEffect(() => {
    //change step if step=1
    if (bookId) {
      const storyStep = localStorage.getItem(LOCAL_STORAGE_STORY_STEP);
      if (storyStep) {
        switch (storyStep) {
          case STORY_STEP_BASIC_INFO:
            break;
          case STORY_STEP_CHARACTER_BUILD:
            setCurrentStep(2);
            break;
          case STORY_STEP_ILLUSTRATION_CREATE:
            setCurrentStep(3);
            break;
          case STORY_STEP_PURCHASE_DOWNLOAD:
            setCurrentStep(6);
            break;
        }
      } else localStorage.removeItem(LOCAL_STORAGE_STORY_STEP); //*/
    }
  }, [bookId, localStorage.getItem(LOCAL_STORAGE_STORY_STEP)]);

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_BOOK_ID)) resetStory();
  }, [localStorage.getItem(LOCAL_STORAGE_BOOK_ID)]);
  // ***************************************
  // STEP ONE - STORY SUBMISSION
  // ***************************************

  useEffect(() => {
    if (uploadedImageUrl)
      setStoryData((prevData) => ({
        ...prevData,
        uploadedImage: uploadedImageUrl,
      }));
    /*
    if (uploadedImageUrl)
      if (illustrationCharacter?.pageIndex)
        setIllustrationCharacter((prevData) => ({
          ...prevData,
          uploadedImage: uploadedImageUrl,
        }));
      else
        setStoryData((prevData) => ({
          ...prevData,
          uploadedImage: uploadedImageUrl,
        }));//*/
  }, [uploadedImageUrl]);

  const startStepOne = async (setLoader) => {
    const allowBookCreation = await checkUserBookCreationStatus(user?.email);
    //console.log(allowBookCreation, "allowBookCreation");
    if (allowBookCreation) {
      setCurrentStep(1);
      setCurrentStoryStep(STORY_STEP_BASIC_INFO);
      setShowStoryForm(true);
      if (storyData) {
        setChildName(storyData?.childName || "");
        setChildAge(storyData?.childAge || "");
        setStoryTopic(storyData?.storyTopic || "");
        setStoryType(storyData?.storyType || "");
        setWritingStyle(storyData?.writingStyle || "");
        setPageTotal(storyData?.pageTotal || "");
        setSelectedArtStyle(storyData?.artStyle || null);
        setStoryLanguage(storyData?.storyLanguage || STORY_LANGUAGE_ENGLISH);
        setSelectedStoryConfig(storyData?.childAge || null);
        // if(config)  setMaxpages(storyconfig[storyData?.childAg e]?.pages_total || 16);
        // console.log("MAX PAGESSSS", storyconfig[storyData?.childAge], storyconfig[storyData?.childAge]?.pages_total);
      }
    } else {
      Toast(
        "You have reached your story creation limit.  Click on 'Illustration & Story Credits' or upgrade your plan.",
        true
      );
      if (setLoader) setLoader(false);
    }
  };

  const [previewImage, setPreviewImage] = useState(null);

  const startStepTwo = () => {
    setCurrentStep(2);
  };

  // ***************************************
  // STEP THREE - STORY CREATION
  // ***************************************

  // const [isLoading, setIsLoading] = useState(false);

  const setLoadingStatus = (page, isLoading) => {
    setLoaderImageGeneration((prevLoader) => ({
      ...prevLoader,
      [page]: isLoading,
    }));
  };

  useEffect(() => {
    // if (currentStep === 3 && uploadedImageUrl) handleStepThree();

    if (currentStep === 3) handleStepThree(); //comented ..open later
  }, [currentStep, characterDescription, uploadedImageUrl]);

  const handleStepThree = async () => {
    if (!bookId) {
      console.error("bookId is undefined or not set");
      return;
    }

    // setIsLoading(true); // Start loading

    try {
      const magicResponse = await callAPI("api/magic", {
        bookId,
        existingCharacterId: stateData?.characterId,
        existingCharacterPortrait: currentPortrait,
      });
      if (magicResponse?.error) {
        Toast("Unexpected Error. Please Try Again!", true);
        setStoryData((prevData) => ({
          ...prevData,
          storybuildingError: true,
        }));
        console.log("magic error==== step=2 ");
        setCurrentStoryStep(STORY_STEP_CHARACTER_BUILD);
        setCurrentStep(2);
      } else {
        // setStoryPages(magicResponse?.story?.pages);
        setBookTitle(magicResponse?.story?.title);
        // setBookSummary(magicResponse?.story?.summary);
      }
    } catch (error) {
      Toast("Unexpected Error. Please Try Again!", true);
      console.error("Error during story generation request:", error);
      setStoryData((prevData) => ({
        ...prevData,
        storybuildingError: true,
      }));
      console.log("magic catch error==== step=2 ");
      setCurrentStep(2);
    } finally {
      // setIsLoading(false); // Stop loading
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  // ***************************************

  // STEP FOUR - IMAGE GENERATION
  // ***************************************
  useEffect(() => {
    // console.log("tfstfstfstftsf", isTitleModalOpen);

    if (currentStep === 3) {
      const interval = setInterval(() => {
        if (modalOpen === false && imagesGenerated === false && isTitleModalOpen === false)
          displayBookPages();
      }, 20000); // Polling every 20 seconds

      return () => clearInterval(interval);
    }
  }, [currentStep, bookId, modalOpen, imageGenerationStarted, imagesGenerated]);

  // useEffect(() => {
  //   goToTopOfPage();
  // }, [currentStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
    //  const scrollElement = document.querySelector("div#root"); // update with your scrollable container selector
    //  if (scrollElement) {
    //    goToTopOfPage(scrollElement);
    //   // window.scrollTo(0, 0);
    //  } else {
    //    goToTopOfPage(window); // fallback to window scrolling
    //   // window.scrollTo(0, 0);
    //  }
  }, [currentStep]);

  const startImageGeneration = async () => {
    let bookDetails = await fetchBookData(localStorage.getItem("bookId"));
    const showErrorMsg =
      user?.plan?.name === PROMO_PLAN && bookDetails?.creditsPerBookLimit === 0 ? false : true;
    //  console.log(
    //    "showErrorMsg",
    //    showErrorMsg,
    //    user?.plan?.name === PROMO_PLAN , bookDetails?.creditsPerBookLimit === 0
    //  );

    const status = await doStartImageGeneration(bookId, showErrorMsg);
    // if (status === -1) navigateTo("/ImageTokens");
    if (status === -1) {
      if (user?.plan?.name === PROMO_PLAN) {
        // navigateTo("/myPlans");
        setStateData((prevData) => ({
          ...prevData,
          purchasePlan: true,
        }));
      }
    }
  }; //*/

  const displayBookPages = async () => {
    const fetchedBookData = await fetchBookData(bookId);
    if (fetchedBookData && fetchedBookData.pages) {
      sortPages(fetchedBookData, setBookData);

      let allImagesGenerated = true;
      fetchedBookData &&
        Object.entries(fetchedBookData.pages).map((page) => {
          if (!page[1]?.imageURL) allImagesGenerated = false;
        });

      setImagesGenerated(allImagesGenerated);

      if (allImagesGenerated === false && imageGenerationStarted === false && bookId) {
        setImageGenerationStarted(true);
        startImageGeneration();
      }

      if (allImagesGenerated) setImageGenerationStarted(false);
    }
  };

  // ***************************************
  // STEP FIVE - APPROVE COVER
  // ***************************************

  const [selectedCoverUrl, setSelectedCoverUrl] = useState(null);

  const startStepSix = async () => {
    try {
      const coverURL = selectedCoverUrl ? selectedCoverUrl : bookData?.pages?.page1?.imageURL;
      await callAPI("api/update-cover", { bookId, coverURL });
      setCurrentStep(6);
      setCurrentStoryStep(STORY_STEP_PURCHASE_DOWNLOAD);
    } catch (error) {
      console.error("Error updating cover:", error);
    }
  };

  useEffect(() => {
    // getPlans(setPlans);
    getstoryConfig(setStoryConfig);
    if (localStorage.getItem("storyStep") === STORY_STEP_BASIC_INFO) startStepOne();
    checkSingleBookPlan(user?.planRef?._path?.segments[1], setSingleBookPlan);
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("storyStep") === STORY_STEP_BASIC_INFO)
  //     setMaxpages(storyconfig[storyData?.childAge]?.pages_total || 16);
  // }, [storyconfig]);

  useEffect(() => {
    if (user) checkSubscribedToPlan(user, setLoaders, setPlanSubscribed, loaders);
  }, [user]);

  useEffect(() => {
    setPageOptions();
    let newOptions = [];
    for (let i = 16; i <= maxPages; i = i + 4) {
      newOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    setPageOptions(newOptions);
  }, [maxPages]);

  const generatePdf = async (bookId) => {
    setLoader(true);
    try {
      const result = await callAPI(`api/pdf-generator/pdf?bookId=${bookId}`);

      if (result?.pdfUrl) setPdfUrl(result?.pdfUrl);
      setLoader(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoader(false);
    }
  };

  if (isAdmin(user)) {
    return <Navigate to="/admin/dashboard" />;
  }
  //console.log("builder selectedArtStyle", selectedArtStyle);

  const goBack = () => {
    navigateTo(-1);
    setShowThanksNote(false);
  };
  // console.log("Story data and book data", user, user?.verified,  user?.plan?.name);
  return (
    <div className="App mx-auto">
      <NavigationBar />
      {stateData.purchasePlan === true && (
        <Modal
          title="Plan Purchase"
          description="Please choose a plan to complete your book or finish this book for free in slow mode."
          doProcess={() => navigateTo("/myPlans")}
          submitButtonTitle="Subscribe to Plan"
          showCloseButton={false}
          showPromoFreeBook={true}
          setImageGenerationStarted={setImageGenerationStarted}
        />
      )}
      {showThanksNote ? (
        <ThankYou goBack={goBack} />
      ) : (
        <div className="flex bg-storytan  p-0 md:p-4 mx-auto min-h-[70vh]">
          {showStoryForm && !showBookCover && currentStep === 3 && <ToolBar setMode={setMode} />}
          <div className="w-[100vw] md:max-w-7xl mx-auto">
            {/* {user && user?.verified && <StepsBar currentStep={currentStep} />} */}

            {!showStoryForm && currentStep === 0 && (
              <div className="text-left mb-8 text-sm text-storyblue">
                <div className="grid grid-cols-1 items-center md:grid-cols-2 gap-4 mt-4">
                  {user && user.status === USER_STATUS_ACTIVE ? (
                    planSubscribed || user?.verified === false ? (
                      <div className="flex items-center p-[1rem]">
                        <div>
                          {user && user?.verified === true && user?.plan !== null ? (
                            // parseInt(user?.creditBalance) < parseInt(DEDUCT_CREDITS_PER_IMAGE) ? (
                            //Check if User has credit balance to create book. {user?.plan?.credits} is the per book credits limit
                            // parseInt(user?.creditBalance) < parseInt(user?.plan?.credits) ? (
                            //   <LowCredit />
                            // ) : (
                            <StepKickStart
                              startStepOne={startStepOne}
                             
                            />
                          ) : user?.verified === false ? (
                            // )
                            <AccountVerfication setLoaders={setLoaders} loaders={loaders} />
                          ) : (
                            <PlanPurchase
                              setShowThanksNote={setShowThanksNote}
                              setPlanSubscribed={setPlanSubscribed}
                            />
                          )}
                        </div>
                      </div>
                    ) : loaders.planExist ? (
                      <div className="flex justify-center items-center">
                        <Loader loader={loaders.planExist} />
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center items-center">
                          <Loader loader={true} />
                        </div>
                        {/* // <PurchasePlan user={user} setPlanSubscribed={setPlanSubscribed} /> */}
                        {/* <h1>testsssttttttttttttttttt</h1> */}
                        {/* <PlanPurchase
                          setShowThanksNote={setShowThanksNote}
                          setPlanSubscribed={setPlanSubscribed}
                        /> */}
                      </>
                    )
                  ) : !showLogin ? (
                    <>
                      <SignUp signUpStep={signUpStep} setSignUpStep={setSignUpStep} plan={paramPlan} />
                    </>
                  ) : (
                    <Login setSignUpStep={setSignUpStep} />
                  )}
                  <div>
                    <img
                      className=" animate__animated animate__rubberBand mx-auto max-w-[80%] shadow-2xl"
                      src="/bookcover.png"
                      alt="Book Cover"
                    />
                  </div>
                </div>
              </div>
            )}

            {showStoryForm && currentStep == 1 && (
              <StepStoryInformation
                setBookId={setBookId}
                setSelectedBookId={setSelectedBookId}
                setLoader={setLoader}
                setShowLowCreditModal={setShowLowCreditModal}
                setSelectedStoryConfig={setSelectedStoryConfig}
                setMaxpages={setMaxpages}
                setShowDescription={setShowDescription}
                setStoryTopic={setStoryTopic}
                setStoryType={setStoryType}
                setChildName={setChildName}
                setArtStyle={setArtStyle}
                setChildAge={setChildAge}
                setPageTotal={setPageTotal}
                setWritingStyle={setWritingStyle}
                bookId={bookId}
                currentStep={currentStep}
                childName={childName}
                childAge={childAge}
                storyTopic={storyTopic}
                storyType={storyType}
                writingStyle={writingStyle}
                pageTotal={pageTotal}
                artStyle={artStyle}
                storyconfig={storyconfig}
                storyExample={storyExample}
                selectedStoryConfig={selectedStoryConfig}
                wordRange={wordRange}
                startStepTwo={startStepTwo}
                pageOptions={pageOptions}
                loader={loader}
                showLowCreditModal={showLowCreditModal}
                showDescription={showDescription}
                storyLanguage={storyLanguage}
                setStoryLanguage={setStoryLanguage}
                selectedArtStyle={selectedArtStyle}
                setSelectedArtStyle={setSelectedArtStyle}
                setStoryData={setStoryData}
                storyData={storyData}
              />
            )}

            {showStoryForm && currentStep === 2 && (
              <StepCharacterBuilder
                setStateData={setStateData}
                setLibrary={setLibrary}
                setStartInterval={setStartInterval}
                setImageUploading={setImageUploading}
                setCharacterDescription={setCharacterDescription}
                setLoaders={setLoaders}
                setCharacterOutlineByUser={setCharacterOutlineByUser}
                setSelectedImage={setSelectedImage}
                setUploadedImageUrl={setUploadedImageUrl}
                setCurrentPortraitList={setCurrentPortraitList}
                setCurrentPortrait={setCurrentPortrait}
                setPreviewImage={setPreviewImage}
                setShowCharacterLibrary={setShowCharacterLibrary}
                setCurrentStep={setCurrentStep}
                showCharacterLibrary={showCharacterLibrary}
                library={library}
                loaders={loaders}
                currentStep={currentStep}
                imageUploading={imageUploading}
                draftId={draftId}
                resetStory={resetStory}
                selectedImage={selectedImage}
                currentPortrait={currentPortrait}
                showFileInput={showFileInput}
                characterGenerationProgress={characterGenerationProgress}
                uploadedImageUrl={uploadedImageUrl}
                currentPortraitList={currentPortraitList}
                previewImage={previewImage}
                storyPreview={storyPreview}
                characterOutlineByUser={characterOutlineByUser}
                // userCreditBalance={userCreditBalance}
                bookId={bookId}
                artStyle={artStyle}
                stateData={stateData}
                getCharacterLibary={getCharacterLibary}
                imageCharacterPrompt={imageCharacterPrompt}
                setimageCharacterPrompt={setimageCharacterPrompt}
                resetCharacter={resetCharacter}
                selectedArtStyle={selectedArtStyle}
                setSelectedArtStyle={setSelectedArtStyle}
              />
            )}

            {showStoryForm && currentStep === 3 && (
              <StepStoryBuilder
                setLoadingStatus={setLoadingStatus}
                // setUserCreditBalance={setUserCreditBalance}
                setStateData={setStateData}
                setShowImageHistory={setShowImageHistory}
                setShowLowCreditModal={setShowLowCreditModal}
                setCurrentStep={setCurrentStep}
                setBookTitle={setBookTitle}
                // setStoryPages={setStoryPages}
                setMode={setMode}
                setModalOpen={setModalOpen}
                setModalMode={setModalMode}
                setShowBookCover={setShowBookCover}
                setSelectedCoverUrl={setSelectedCoverUrl}
                showLowCreditModal={showLowCreditModal}
                showImageHistory={showImageHistory}
                showBookCover={showBookCover}
                showStoryForm={showStoryForm}
                stateData={stateData}
                bookId={bookId}
                bookData={bookData}
                bookTitle={bookTitle}
                imagesGenerated={imagesGenerated}
                selectedCoverUrl={selectedCoverUrl}
                loader={loader}
                loaderImageGeneration={loaderImageGeneration}
                startStepSix={startStepSix}
                currentStep={currentStep}
                draftId={draftId}
                mode={mode}
                modalMode={modalMode}
                modalOpen={modalOpen}
                // userCreditBalance={userCreditBalance}
                resetStory={resetStory}
                displayBookPages={displayBookPages}
                summaryModal={summaryModal}
                setSummaryModal={setSummaryModal}
                setBookDataModified={setBookDataModified}
                setShowStoryForm={setShowStoryForm}
                setimageCharacterPrompt={setimageCharacterPrompt}
                imageCharacterPrompt={imageCharacterPrompt}
                setShowCharacterLibrary={setShowCharacterLibrary}
                //  isTitleModalOpen={isTitleModalOpen} setIsTitleModalOpen={setIsTitleModalOpen}
              />
            )}

            {showStoryForm && currentStep === 6 && (
              <StepPDFDownloadPurchase
                bookId={bookId}
                stateData={stateData}
                setCurrentStep={setCurrentStep}
                pdfUrl={pdfUrl}
                generatePdf={generatePdf}
                selectedBookId={selectedBookId}
                loader={loader}
              />
            )}
          </div>
        </div>
      )}
      <Footer className="mb-0 pb-4" />
    </div>
  );
}

export default Builder;
