export const HTTP_STATUS_200 = 200;
export const HTTP_STATUS_400 = 400;
export const HTTP_STATUS_403 = 403;
export const HTTP_STATUS_500 = 500;

export const ROLE_USER = "user";
export const ROLE_SUPERADMIN = "super-admin";
export const ROLE_ADMIN = "admin";
export const ROLE_SALES = "customer-service";

export const LOCAL_STORAGE_CURRENT_USER = "current_user";
export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_BOOK_ID = "bookId";
export const LOCAL_STORAGE_EDIT_MODE = "editMode";
export const LOCAL_STORAGE_STORY_STEP = "storyStep";

export const STORY_STEP_BASIC_INFO = "basicInfo";
export const STORY_STEP_CHARACTER_BUILD = "characterBuild";
export const STORY_STEP_ILLUSTRATION_CREATE = "illustrationCreate";
export const STORY_STEP_PURCHASE_DOWNLOAD = "purchaseDownload";

export const TYPE_CHILD_AGE = "TYPE_CHILD_AGE";
export const TYPE_STORY = "TYPE_STORY";
export const TYPE_WRITING_STYLE = "TYPE_WRITING_STYLE";
export const TYPE_ART_STYLE = "TYPE_ART_STYLE";

export const AGE_0_2 = "0-2-year";
export const AGE_3_7 = "3-7-year";
export const AGE_8_12 = "8-12-year";
export const AGE_ADULT = "adult";

export const STORY_TYPE_BEDTIME_STORY = "bedtime";
export const STORY_TYPE_FABLE = "fable";
export const STORY_TYPE_FAIRYTALE = "fairytale";
export const STORY_TYPE_ADVENTURE = "adventure";
export const STORY_TYPE_EDUCATIONAL = "educational";
export const STORY_TYPE_MYSTERY = "mystery";
export const STORY_TYPE_SCIENCE_FICTION = "science_fiction";
export const STORY_TYPE_REALISTIC_FICTION = "realistic_fiction";

export const ART_STYLE_ANIME = "anime";
export const ART_STYLE_LEGO = "lego";
export const ART_STYLE_VIBRANT = "vibrant";
export const ART_STYLE_WATER_COLOR = "water_color";
export const ART_STYLE_CHARCOAL_DRAWING = "charcoal_drawing";
export const ART_STYLE_CARTOON = "cartoon";
export const ART_STYLE_CLASSIC = "classic";
export const ART_STYLE_PAPER_CUT = "paper_cut";
export const ART_STYLE_ILLUSTRATED = "illustrated";
export const ART_STYLE_FOLK_ART = "folk_art";
export const ART_STYLE_PIXEL_STYLE = "pixel_style";
export const ART_STYLE_MINECRAFT_STYLE = "minecraft_style";
export const ART_STYLE_PIXAR_STYLE = "pixar_style";
export const ART_STYLE_CLASSIC_DISNEY_STYLE = "classic_disney_style";
export const ART_STYLE_SIMPSONS_CHARACTER_STYLE = "simpsons_character_style";
export const ART_STYLE_CHIBI_DRAWING_STYLE = "chibi_drawing_style";
export const ART_STYLE_SOUTH_PARK_ANIMATION_STYLE = "south_park_animation_style";
export const ART_STYLE_HANNA_BARBERA = "hanna_barbera";
export const ART_STYLE_ARCANE_CHARACTER = "arcane_character";
export const ART_STYLE_MARVEL_CHARACTER = "marvel_character";
export const ART_STYLE_TOON_BOOM_CHARACTER = "toon_boom_character";
export const ART_STYLE_RICK_AND_MORTY = "rick_and_morty";

export const DUMMY_IMAGE = "./dummy.png";
export const CHARACTER_TYPE_HUMAN = "human";
export const CHARACTER_TYPE_ANIMAL = "animal";
export const CHARACTER_TYPE_FICTIONAL = "fictional";

export const CHARACTER_AGE_UNIT = "year";

export const WRITING_STYLE_IMAGINATIVE = "imaginative";
export const WRITING_STYLE_FUNNY = "funny";
export const WRITING_STYLE_HEART_WARMING = "heartwarming";
export const WRITING_STYLE_ACTION_PACKED = "action_packed";
export const WRITING_STYLE_NOSTALGIC = "nostalgic";
export const WRITING_STYLE_EMPOWERING = "empowering";
export const WRITING_STYLE_SPOOKY = "spooky";
export const WRITING_STYLE_EDUCATIONAL = "educational";

export const STORY_LANGUAGE_ENGLISH = "english";
export const STORY_LANGUAGE_SPANISH = "Spanish";
export const STORY_LANGUAGE_FRENCH = "french";
export const STORY_LANGUAGE_GERMAN = "german";

export const DEDUCT_CREDITS_PER_IMAGE = 4;

export const ERROR_MODULE_CHARACTER_GENERATION = "character_generation";
export const ERROR_MODULE_IMAGE_GENERATION = "image_generation";

export const COLLECTION_BOOKS = "booknew";

export const COUPON_FOR_BOOK_PURCHASE = "book_purchase";
export const COUPON_FOR_PLAN_PURCHASE = "plan_purchase";
export const COUPON_FOR_CREDITS_PURCHASE = "credits_purchase";
export const COUPON_FOR_STORY_PURCHASE = "story_purchase";
export const SHIPPING_COST = 6.99;

export const COUPON_DURATION_ONCE = "once";
export const COUPON_DURATION_REPEATING = "repeating";
export const COUPON_DURATION_FOREVER = "forever";

export const USER_STATUS_ACTIVE = "active";
export const USER_STATUS_INACTIVE = "inactive";

export const ADDITIONAL_PAGE_FEE = 0.95;

export const ORDER_STATUS_AWAITING_SHIPMENT = "awaiting_shipment";
export const ORDER_STATUS_AWAITING_PAYMENT = "awaiting_payment";
export const ORDER_STATUS_SHIPPED = "shipped";
export const ORDER_STATUS_ONHOLD = "on_hold";
export const ORDER_STATUS_CANCELLED = "cancelled";

export const ORDER_TYPE_SUBSCRIPTION_PURCHASE = "subscription";
export const ORDER_TYPE_BOOK_PURCHASE = "book";
export const ORDER_TYPE_CREDITS_PURCHASE = "image_credits";

export const USER_SETTING_MARKETING_EMAILS = "USER_ALLOW_MARKETING_EMAILS";
export const USER_SETTING_PROMOTIONAL_EMAILS = "USER_ALLOW_PROMOTIONAL_EMAILS";

export const GOAFFPRO_PUBLIC_KEY = "w6dmdvcn04";

export const PURCHASE_TYPE_CREDITS = "credits";
export const PURCHASE_TYPE_STORY = "story";

export const PROMO_PLAN = "Promo";
export const BROWSER_REFRESH_POPUP_PARAM = "show-refresh-popup";

export const MID_JOURNEY_MODE_RELAX = "relaxed";
export const MID_JOURNEY_MODE_FAST = "fast";

// export const STORY_INTRO_VIDEO = "https://www.youtube.com/embed/iS-v9t1cwBY";
// export const STORY_CHARCTER_BUILDER_VIDEO = "https://www.youtube.com/embed/EQBrOUiaHsk";
// export const STORY_EDITOR_VIDEO = "https://www.youtube.com/embed/3XtA7gaUnQI";
// export const STORY_PURCHASE_AND_VIEW_VIDEO = "https://www.youtube.com/embed/_yEh_nWBKx0";

