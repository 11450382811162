import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useUser } from "../../context/userContext";
import Logo from "./logo.webp";
import { LOCAL_STORAGE_TOKEN, ROLE_ADMIN, ROLE_SALES, ROLE_SUPERADMIN } from "../../helper/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Menu } from "@headlessui/react";
const Sidebar = ({ onOptionClick, selectedOption }) => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [openMenu, setOpenMenu] = useState(false);
  const options = (() => {
    switch (user?.role) {
      case ROLE_SALES:
        return ["Customers"];
      case ROLE_ADMIN:
        return ["Customers", "Error Logs"];
      case ROLE_SUPERADMIN:
        return ["Dashboard", "Customers", "Coupons", "Settings", "Error Logs", "Admin Users", "Plans","Art Styles", "Mid Journey Stats", "Training Videos"];
      default:
        return [];
    }
  })();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
      });
  };
  return (
    <>
      <div className="sm:hidden bg-white flex p-4 items-center justify-between min-h-screen">
        <Menu>
          <Menu.Button className="rounded-full focus:outline-none">
            <FontAwesomeIcon
              icon={faBars}
              className="cursor-pointer text-storyblue text-3xl hover:text-storygreen"
              title="Menu"
              onClick={() => setOpenMenu(!openMenu)}
            />
          </Menu.Button>
          {openMenu && (
            <Menu.Items className="absolute top-[3rem] left-[1rem] w-36 z-0 origin-top-right bg-gray-100 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((option) => (
                <Menu.Item key={option}>
                  {({ active }) => (
                    <div
                      className={`p-2 text-left rounded-md m-1 hover:bg-storytan text-[#062457] cursor-pointer ${
                        selectedOption === option ? "font-bold bg-storytan" : ""
                      }`}
                      onClick={() => {
                        onOptionClick(option);
                        setOpenMenu(false);
                      }}
                    >
                      {option}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          )}
        </Menu>
        <img className="h-10 md:h-12 w-auto" src={Logo} alt="Build My Story" />
        <button
          onClick={() => handleLogout()}
          className="flex justify-center bg-storyblue text-white font-bold rounded-md p-2"
        >
          Sign Out
        </button>
      </div>
      <aside className="hidden sm:flex bg-white text-gray-200 w-56 flex-col justify-between py-5">
        <nav className="flex flex-col justify-between">
          <div className="flex items-center p-4">
            <img className="h-10 md:h-12 w-auto" src={Logo} alt="Build My Story" />
          </div>
          <h3 className="mx-auto text-storygreen mb-4">
            Welcome <b>{user?.firstName}!</b>
          </h3>
          <ul>
            {options.map((option) => (
              <li
                key={option}
                className={`p-2 text-left rounded-md m-1 hover:bg-storytan text-[#062457] cursor-pointer ${
                  selectedOption === option ? "font-bold bg-storytan" : ""
                }`}
                onClick={() => onOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </nav>
        <button
          onClick={() => handleLogout()}
          className="flex justify-center bg-storyblue text-white font-bold rounded-md m-1 p-2"
        >
          Sign Out
        </button>
      </aside>
    </>
  );
};
export default Sidebar;
