

const ThankYou = ({ goBack }) => {
  return (
    <div className="bg-storytan flex flex-col justify-center items-center gap-8 h-[25.5rem]">
      <h2 className="text-center text-2xl md:text-4xl mb-4 font-bold text-storyblue">
        Thank you for your Order!
      </h2>
      <button
        className={
          "w-[12rem] md:w-[20rem] bg-storygreen text-white hover:text-white font-bold rounded-md text-base p-2"
        }
        onClick={() => {
          goBack();
        }}
      >
        Close
      </button>
    </div>
  );
};

export default ThankYou