import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { setCurrentStoryStep } from "../../helper/helper";
import { STORY_PURCHASE_AND_VIEW,
  //  STORY_PURCHASE_AND_VIEW_VIDEO,
   STORY_STEP_ILLUSTRATION_CREATE } from "../../helper/Constant";
import { IconChecked } from "./builderHelpers";
import Guide from "../../components/Common/Guide";
import { useStory } from "../../context/storyContext";
import { useApp } from "../../context/appContext";
import { useUser } from "../../context/userContext";
import TrainingPopup from "../trainingvideos/TrainingPopup";
import { callAPI } from "../../services/apiService";
import { useEffect, useState } from "react";

const StepPDFDownloadPurchase = ({
  bookId,
  stateData,
  setCurrentStep,
  pdfUrl,
  generatePdf,
  selectedBookId,
  loader,
}) => {
  const navigateTo = useNavigate();
  //import storyData from storyContext
  const { bookData } = useApp();
  const { user, trainingVideos } = useUser();
  // console.log("showTrainingPopup 1", stateData?.showDownloadPDF);




  //section for training videosssssss
  const [showPopUp, setshowPopUp] = useState(true);

  // Show popup after a delay
  // useEffect(() => {
  //   const popupTimer = setTimeout(() => {
  //     setshowPopUp(true);
  //   }, 3000);

  //   return () => clearTimeout(popupTimer);
  // }, []);

  const onClose = async () => {
    try {
      await callAPI("user/updateUser", {
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        settings: user?.settings,
        screentoUpdate: "screen4",
      });
    } catch (error) {
      console.log("error updating user training screen", error.message);
    } finally {
      setshowPopUp(false);
    }
  };

  // console.log("training1", trainingVideos?.STORY_PURCHASE_AND_VIEW_VIDEO);

  return (
    <div className="text-left p-4 mb-8 text-sm text-storyblue animate__animated animate__slideInRight ">
      {showPopUp === true && user?.showTrainingPopup["screen4"] === true && (
        <TrainingPopup
          videoUrl={stateData?.showDownloadPDF ? trainingVideos?.STORY_PURCHASE_AND_VIEW_VIDEO : null}
          onClose={onClose}
          showScreenName={"screen4"}
          user={user}
          focus={showPopUp}
        />
      )}
      <div className=" w-full flex flex-col md:flex-row justify-between ">
        <div className=" flex items-center align-end mb-4">
          <h2 className="text-left text-2xl md:text-4xl  font-bold text-storyblue">
            Flip Book/Purchase Your Book
          </h2>
          <div>
            <Guide className="mt-1" />
            {/* <Guide
              className="mt-1"
              to={stateData?.showDownloadPDF ? trainingVideos?.STORY_PURCHASE_AND_VIEW_VIDEO : null}
              screen={"screen4"}
              user={user}
            /> */}
          </div>
        </div>

        {stateData?.showDownloadPDF && (
          <button
            className="w-[25%] md:w-[8rem] px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11 "
            onClick={() => {
              setCurrentStep(3);
              setCurrentStoryStep(STORY_STEP_ILLUSTRATION_CREATE);
            }}
          >
            Back
          </button>
        )}
      </div>

      <p className="mb-4">
        Explore personalized storytelling with this 20-page custom children&apos;s book, featuring characters
        inspired by your family and loved ones, brought to life through AI-generated artwork in your chosen
        style. Each page is a unique, heartwarming representation of familiar bonds and affection.
      </p>

      <div className="mt-8">
        <div className="mx-auto">
          <div className="mx-auto grid  grid-cols-1 gap-8 lg:grid-cols-2">
            <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
              <div>
                <h3 id="tier-hobby" className="text-base font-semibold leading-7 text-storyblue">
                  Flip Book
                </h3>
                <div className="mt-4 flex items-baseline gap-x-2">
                  <span className="text-3xl md:text-5xl font-bold tracking-tight text-gray-900">Free </span>
                </div>
                <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                  <li className="flex gap-x-3">
                    {IconChecked()}A book, rich with storytelling and personalized illustrations in your
                    chosen art style.
                  </li>
                  <li className="flex gap-x-3">
                    {IconChecked()}
                    Downloadable and accessible anytime, anywhere.
                  </li>
                </ul>
              </div>

              <Link to={`/bookflip/${bookId}`}>
                <div
                  className="mt-8 flex flex-row justify-center items-center gap-2 rounded-md
                  bg-storyblue px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white
                   shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                   focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  View Flip Book
                </div>
              </Link>

              {/* {!bookData?.pdfUrl ? (
                <>
                  <button
                    onClick={() => {
                      generatePdf(selectedBookId);
                    }}
                    disabled={loader}
                    className="mt-8 flex flex-row justify-center items-center gap-2 rounded-md bg-storyblue px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Generate PDF
                    <Loader loader={loader} />
                  </button>
                </>
              ) : (
                <Link
                  to={pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-8 block rounded-md bg-storyblue px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  View Story as PDF
                </Link>
              )} */}
            </div>
            <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
              <div>
                <h3 id="tier-hobby" className="text-base font-semibold leading-7 text-storyblue">
                  {
                    // user?.plan?.freePhysicalBookStatus !== true &&
                    // user?.freePhysicalBookCreated > user?.freePhysicalBookQuantity
                    user?.plan?.freePhysicalBookStatus === true &&
                    parseInt(user?.freePhysicalBookCreated ? user?.freePhysicalBookCreated : 0) <
                      parseInt(user?.freePhysicalBookQuantity)
                      ? "Redeem your book"
                      : "Printed Book"
                  }
                </h3>
                {
                  // user?.plan?.freePhysicalBookStatus !== true &&
                  //   user?.freePhysicalBookCreated > user?.freePhysicalBookQuantity &&
                  user?.plan?.freePhysicalBookStatus !== true ||
                    (parseInt(user?.freePhysicalBookCreated ? user?.freePhysicalBookCreated : 0) >=
                      parseInt(user?.freePhysicalBookQuantity || 0) && (
                      <div className="mt-4 flex items-baseline gap-x-2">
                        <span className="text-3xl w-[30%] md:text-5xl font-bold tracking-tight text-gray-900">
                          $ 12.95{" "}
                        </span>
                        <span className="text-xl text-grey-400">+ shipping & handling</span>
                      </div>
                    ))
                }
                <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                  <li className="flex gap-x-3">
                    {IconChecked()}
                    {/* A printed book, rich with AI-crafted storytelling in your chosen art style */}Up to a
                    32 page printed soft cover book in you chosen art style.
                  </li>
                  <li className="flex gap-x-3">
                    {IconChecked()}
                    High-quality print, perfect for hands-on reading experiences. Shipped to your address.
                  </li>
                  <li className="flex gap-x-3">
                    {IconChecked()}
                    Durable and child-friendly materials for long-lasting enjoyment
                  </li>
                  <li className="flex gap-x-3">
                    {IconChecked()}A unique keepsake to cherish family memories and moments
                  </li>
                </ul>
              </div>
              <Link
                onClick={() => navigateTo(`/bookpurchase/${bookId}`)}
                to="#"
                rel="noopener noreferrer"
                className="mt-8 block h-11 rounded-md bg-storyblue px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {
                  user?.plan?.freePhysicalBookStatus === true &&
                  parseInt(user?.freePhysicalBookCreated ? user?.freePhysicalBookCreated : 0) <
                    parseInt(user?.freePhysicalBookQuantity)
                    ? "Ship My Book"
                    : "Purchase Printed Book"

                  // user?.plan?.freePhysicalBookStatus !== true &&
                  // user?.freePhysicalBookCreated > user?.freePhysicalBookQuantity
                  //   ? "Purchase Printed Book"
                  //   : "Ship My Book"
                }
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepPDFDownloadPurchase;
