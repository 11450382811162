import React from 'react';
import {createRoot} from 'react-dom/client'; // Importing createRoot
import './index.css'; // Global styles
import App from './App'; // Importing the App component
import {AppProvider} from "./context/appContext";
import {StoryProvider} from "./context/storyContext";
import {UserProvider} from "./context/userContext";
// Any other global imports, like context providers or libraries

// Create a root
const container = document.getElementById('root');
const root = createRoot(container);

// Render the app within the root
root.render(
    <React.Fragment>
        <UserProvider>
            <AppProvider>
                <StoryProvider>
                    <App/>
                </StoryProvider>
            </AppProvider>
        </UserProvider>
    </React.Fragment>
);
