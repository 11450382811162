import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import { editStory, getPlans, getstoryConfig } from "../../pages/Builder/builderHelpers";
import { trackPageView } from "goaffpro-client";
import { capitalizeFirstCharacter, replaceFromString, setCurrentStoryStep } from "../../helper/helper";
import { callAPI } from "../../services/apiService";
import {
  COUPON_FOR_PLAN_PURCHASE,
  HTTP_STATUS_200,
  LOCAL_STORAGE_BOOK_ID,
  PROMO_PLAN,
} from "../../helper/Constant";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { Toast } from "./Toast";
import Loader from "./Loader";
import { useUser } from "../../context/userContext";
import SubmitButton from "./SubmitButton";
import ThankYou from "./ThankYou";
import { useApp } from "../../context/appContext";
import { useStory } from "../../context/storyContext";
import Heading from "./Heading";
import Guide from "./Guide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
const PlanPurchase = ({ setShowThanksNote, setPlanSubscribed = null, className = null }) => {
  const navigateTo = useNavigate();
  const { user } = useUser();
  const { setStoryData } = useStory();
  const { bookId, bookData, setDraftId } = useApp();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanDescription, setSelectedPlanDescription] = useState(null);
  const [plans, setPlans] = useState([]);
  const [couponCode, setCouponCode] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [couponClass, setCouponClass] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [couponLoader, setCouponLoader] = useState(false);
  const [price, setPrice] = useState(null);
  const [placeholder, setPlaceholder] = useState("Coupon (Optional)");
  const [paymentloader, setPaymentLoader] = useState(false);
  const [couponDescription, setCouponDescription] = useState(null);
  const [trialDays, setTrialDays] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    getPlans(setPlans, user?.plan?.name, true);
    // getPlans(setPlans, null, true);
    // getstoryConfig(setStoryConfig);
    //goaffpro page tracking
    trackPageView("Plan Purchase");
  }, []);

  useEffect(() => {
    getPlans(setPlans, user?.plan?.name, true);
  }, [user?.plan]);

  useEffect(() => {
    const selectedObj = plans?.find((value) => value.id === selectedPlan);
    setSelectedPlanDescription(selectedObj);
    setPrice(selectedObj?.cost);
  }, [selectedPlan, plans]);

  const handlePlanChange = (event) => {
    // const selectedIndex = event.target.value - 1;
    const selectedObj = plans.find((value) => value.id === event.target.value);
    setSelectedPlanDescription(selectedObj);
    setSelectedPlan(event.target.value);
    setTrialDays(selectedObj?.trialDays > 0 ? selectedObj?.trialDays : null);
  };

  const handleApplyPromoCode = async () => {
    setCouponLoader(true);
    // console.log("applying couponnn", couponCode);
    // if (couponCode?.startsWith("GC")) {
    //   const response = await callAPI("user/apply-gift-card", {
    //     couponCode,
    //   });
    //   console.log("Response of giftcarddddd", response);
    // } else {
    const response = await callAPI("user/coupon-validate", {
      couponCode,
      amount: selectedPlanDescription?.cost,
      requestType: COUPON_FOR_PLAN_PURCHASE,
    });

    if (response?.status === HTTP_STATUS_200) {
      setCouponClass("text-storygreen");
      let discountedPrice = parseFloat(response?.data?.discount).toFixed(2);
      setCouponDescription(response?.data?.couponDescription);
      setCouponDiscount(discountedPrice);
      setPrice(parseFloat(response?.data?.price).toFixed(2));
      setCouponCode(couponCode);
      Toast("Coupon Applied");
      setCouponLoader(false);
    } else {
      // setCouponCode(null);
      setCouponClass("text-storyred");
      if (response?.data?.couponCode?.startsWith("GC")) {
        Toast("Invalid Gift Card", true);
      } else {
        Toast("Invalid Coupon", true);
      }
      // Toast("Invalid Coupon", true);

      setCouponLoader(false);
    }
    // }
  };

  const handleSubmit = async (e) => {
    if (cardHolderName.length <= 1) {
      Toast("Please enter card holder name", true);
      return;
    }
    if (!stripe || !elements)
      // e.preventDefault();
      return;
    setPaymentLoader(true);
    const resultStripe = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: user?.email,
      },
    });
    if (resultStripe?.error) {
      setPaymentLoader(false);
      Toast(resultStripe?.error?.message, true);
    } else {
      const userData = {
        email: user?.email,
        plan: selectedPlan,
        paymentMethod: resultStripe?.paymentMethod?.id,
        cardHolderName,
        coupon: couponCode,
        bookId: localStorage.getItem(PROMO_PLAN) ? bookId : null,
      };
      // await callAPI("user/update", userData);
      const paymentResponse = await callAPI("plans/payment", userData);
      if (paymentResponse?.status === HTTP_STATUS_200) {
        if (setPlanSubscribed) setPlanSubscribed(true);
        if (localStorage.getItem(PROMO_PLAN)) {
          localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
          localStorage.removeItem(PROMO_PLAN);
          setCurrentStoryStep(null);
          Toast("You have received a free promo book we hope you enjoy it.");
          setTimeout(() => {
            editStory(bookData, setDraftId, setStoryData, navigateTo);
            // window.location.reload();
          }, 5000);
        } else Toast("Plan Purchased Successfully");
        setShowThanksNote(true);
      } else Toast(paymentResponse?.message || "Unexpected Error Occurred", true);
      setPaymentLoader(false);
    }
  };
  // console.log("usr", selectedPlanDescription?.mics, selectedPlanDescription?.mics);

  return (
    <form
      id="form-signup"
      // name="form-signup"
      // className="text-start flex flex-col gap-3"
      className="w-full flex flex-col gap-2 "
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {user?.plan === null ||
      user?.plan?.name === PROMO_PLAN ||
      user?.plan?.freePhysicalBookStatus !== true ? (
        <div className="flex flex-col gap-2 text-base font-medium text-storyblue">
          <div className="flex justify-start items-start gap-4">
            <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
            <h1>Access to "Book Builder" craft personalized children's books for your loved one.</h1>
          </div>
          <div className="flex justify-start items-start gap-4">
            <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
            <h1>Unlimited Story Editing.</h1>
          </div>
          <div className="flex justify-start items-start gap-4">
            <FontAwesomeIcon icon={faCheck} className=" text-2xl text-storylightgreen" />
            <h1>Unlimited PDFs Generated.</h1>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <h1 className={`font-bold text-storyblue ${style ? style : ""}`}>
        {user?.plan && user?.plan?.name !== PROMO_PLAN ? "Upgrade Plan" : "Purchase Plan"}{" "}
      </h1> */}
      <div className=" flex items-center align-end mt-2">
        <Heading
          title={
            user?.plan && user?.plan?.name !== PROMO_PLAN && user?.plan?.freePhysicalBookStatus !== true
              ? "Upgrade Plan"
              : "Purchase Plan"
          }
        />
        {/* <div className="mt-2">
          <Guide />
        </div> */}
      </div>
      <div className="w-full flex justify-between mt-3 ">
        <div className="w-full flex flex-col gap-1">
          <select
            required
            placeholder="Select Plan"
            name="plan"
            id="plan"
            value={selectedPlan}
            className="w-[100%] h-10 rounded-md "
            onChange={(e) => {
              handlePlanChange(e);
            }}
          >
            <option value={""}>Select Plan</option>
            {plans?.map((plan, index) => (
              <option key={index} value={plan.id} className="capitalize">
                {plan.name} - $ {plan.cost} /{" "}
                <span className="capitalize">
                  {capitalizeFirstCharacter(replaceFromString(plan?.chargeCycle, "_-"))}
                </span>
                {/* {plan?.chargeCycle == "one_time" ? "Once" : "/Month"} */}
              </option>
            ))}
          </select>
          {selectedPlanDescription && (
            <div className="bg-white rounded-md border-2 border-storylightgreen flex flex-col gap-1 justify-around items-start w-full my-2 p-2">
              {selectedPlanDescription?.freePhysicalBookStatus === true &&
              selectedPlanDescription?.freePhysicalBookQuantity ? (
                <h1 className="text-md text-storyblue">
                  <strong> Free Physical Book(s): </strong>
                  {selectedPlanDescription?.freePhysicalBookQuantity}
                </h1>
              ) : (
                <h1 className="text-md text-storyblue">
                  <strong> Monthly Stories: </strong> {selectedPlanDescription?.storyCreationLimit}
                </h1>
              )}

              {/* <h1 className="text-md text-storyblue">
                <strong> Monthly Stories: </strong> {selectedPlanDescription?.storyCreationLimit}
              </h1> */}

              <h1 className="text-md text-storyblue capitalize">
                <strong> Cost: </strong> $ {selectedPlanDescription?.cost}
                {" / "}
                {selectedPlanDescription?.chargeCycle?.replaceAll("_", " ")}
              </h1>

              <span className="capitalize whitespace-pre-wrap">{selectedPlanDescription?.mics || ""}</span>
              {/* <h1 className="text-md text-storyblue">
                  <strong> Cost: </strong> $ {selectedPlanDescription?.cost}
                </h1> */}
            </div>
          )}
        </div>
      </div>
      <div
        className={`flex ${className ? className : "justify-between"} gap-2 md:gap-4 mt-3 rounded-md h-10`}
      >
        <input
          type="text"
          name="promoCode"
          // maxLength={6}
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
          placeholder={placeholder}
          className={`p-2 w-[50%] rounded-md uppercase ${couponClass}`}
        />
        <button
          className={`w-60 flex justify-center items-center gap-1 whitespace-nowrap bg-storygreen text-white font-bold rounded-md text-md pr-1 pl-1 h-10 ${!selectedPlanDescription} ? "opacity-50" : ""`}
          onClick={() => handleApplyPromoCode()}
          disabled={!selectedPlanDescription}
        >
          Apply Coupon / Gift Card {couponLoader && <Loader loader={couponLoader} />}
        </button>
      </div>
      {selectedPlanDescription && (
        // <p className="flex pt-2 text-storygreen text-sm">
        //   {" "}
        //   {couponDiscount && (
        //     <p className=" text-storygreen text-sm">
        //       {" "}
        //       Discount applied of{" "}
        //       <b className="pl-1">
        //         {" "}
        //         ${" "}
        //         {parseFloat(couponDiscount) > parseFloat(selectedPlanDescription?.cost)
        //           ? selectedPlanDescription?.cost
        //           : couponDiscount}
        //       </b>
        //       .
        //     </p>
        //   )}{" "}
        //   {/* {trialDays ? `After ${trialDays} trail days , ` : ""} */}
        //   {trialDays ? (
        //     <>
        //       After&nbsp;<strong>{trialDays} trial days</strong>,{" "}
        //     </>
        //   ) : (
        //     ""
        //   )}
        //   You will be charged <b className="pl-1"> $ {price}</b>
        // </p>

        <p className="flex flex-wrap pt-2 text-sm text-storygreen">
          {couponDiscount && (
            <span className="text-storygreen text-sm w-full sm:w-auto">
              Discount applied of{" "}
              <b className="pl-1">
                ${" "}
                {parseFloat(couponDiscount) > parseFloat(selectedPlanDescription?.cost)
                  ? selectedPlanDescription?.cost
                  : couponDiscount}
              </b>
              .
            </span>
          )}
          {trialDays ? (
            <span className="w-full sm:w-auto">
              After&nbsp;<strong>{trialDays} trial days</strong>,{" "}
            </span>
          ) : (
            ""
          )}
          <span className="w-full sm:w-auto">
            You will be charged <b className="pl-1"> ${price}</b>
          </span>
        </p>
      )}
      {couponDiscount && couponDescription?.length > 0 && (
        <div className="flex pt-0">
          <span className=" text-storygreen text-sm">
            <strong> Description: </strong> {couponDescription}
          </span>
        </div>
      )}

      <div className={`w-full mt-3 rounded-md h-10 `}>
        <input
          type="text"
          required
          name="billingCardHolderName"
          value={cardHolderName}
          onChange={(e) => {
            setCardHolderName(e.target.value);
          }}
          placeholder="Card Holder Name"
          className={`p-2 w-full rounded-md`}
        />
      </div>
      <div className="mt-3 bg-white p-2 rounded-md h-10">
        <CheckoutForm />
      </div>
      <button
        disabled={!selectedPlanDescription || paymentloader}
        type="submit"
        className={`w-[50%] flex justify-center gap-2 ${
          !selectedPlanDescription ? "opacity-50" : ""
        } bg-storyblue text-white font-bold rounded-md text-base h-11 items-center mt-5`}
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        Purchase Plan
        <Loader loader={paymentloader} />
      </button>
      {/* <SubmitButton title="Purchase Plan" loader={paymentloader}  /> */}
    </form>
  );
};
export default PlanPurchase;
