import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Uploader from "./Uploader";
import { makeDropdownOptions } from "../../helper/helper";
import { CharacterType } from "./builderHelpers";
import {
  CHARACTER_AGE_UNIT,
  CHARACTER_TYPE_ANIMAL,
  CHARACTER_TYPE_FICTIONAL,
  CHARACTER_TYPE_HUMAN,
} from "../../helper/Constant";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useStory } from "../../context/storyContext";

// import Cropper from "react-easy-crop";
// import ImageCropper from "../../components/Common/ImageCropper";
// import ImageCropModal from "../../components/Common/ImageCropper";

const CharacterDescriptionInputs = ({
  setCharacterOutlineByUser,
  characterOutlineByUser,
  handleImageChange,
  setPreviewImage,
  previewImage,
  selectedImage,
  continueWithoutImage,
  errors,
  setErrors,
  validateField,
  handleDeleteImage,
  currentPortrait,
  setCurrentPortrait,
   setShouldCoverPortrait=null
}) => {
  const [hoveredOnImage, setHoveredOnImage] = useState(false);
  const { setStoryData, storyData } = useStory();

  // const [isImageModalOpen, setIsImageModalOpen] = useState(true);

  // console.log("javi", previewImage);
  useEffect(() => {
    if (storyData) {
      setCharacterOutlineByUser((prevState) => ({
        ...prevState,
        age: storyData?.age,
        ageUnit: storyData?.ageUnit || CHARACTER_AGE_UNIT,
        mics: storyData?.mics,
        gender: storyData?.gender,
        animalType: storyData?.animalType,
        characterType: storyData?.characterType || CHARACTER_TYPE_HUMAN,
        // character: storyData?.character || CHARACTER_TYPE_HUMAN,
        character: storyData?.character,
      }));
    }
  }, [storyData]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    //console.log("user outline", name, value, characterOutlineByUser);
    //  reset form if characterType changed
    if (name === "characterType") {
      if (value === CHARACTER_TYPE_HUMAN) {
        setCharacterOutlineByUser((prevState) => ({
          //       ...prevState,
          ["ageUnit"]: CHARACTER_AGE_UNIT,
          ["characterType"]: CHARACTER_TYPE_HUMAN,
          //
        }));
        setStoryData((prevData) => ({
          ...prevData,
          ["ageUnit"]: CHARACTER_AGE_UNIT,
          ["characterType"]: CHARACTER_TYPE_HUMAN,
        }));
      } else {
        setCharacterOutlineByUser({
          name: value,
          mics: "",
          character: "",
        });
        setStoryData((prevData) => ({
          ...prevData,
          name: value,
          mics: "",
          character: "",
          age: "",
          gender: "",
        }));
      }
    }

    value = name === "age" ? parseInt(value) : value;
    if (name === "age" && value > 0 && value <= 100) {
      // Check if the input field is 'age' and the value is a positive number
      setCharacterOutlineByUser((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setStoryData((prevData) => ({
        ...prevData,
        ["ageUnit"]: CHARACTER_AGE_UNIT,
        ["characterType"]: CHARACTER_TYPE_HUMAN,
      }));

      setStoryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // For other fields or invalid age, update the state directly
      setCharacterOutlineByUser((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setStoryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    validateField(name, value);
  };

  // const handleOpenModal = () => {
  //   console.log("open function called", setIsImageModalOpen);
  //   setIsImageModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   console.log("close function called");
  //   //  setIsImageModalOpen(false);
  // };

  // const handleCrop = (croppedImageData) => {
  //   setCurrentPortrait(croppedImageData);
  //   //  setCroppedImage(croppedImageData); // Store the cropped image data
  // };
  // console.log("isImageModalOpen", isImageModalOpen);
  return (
    <div className="flex flex-col gap-2 mx-auto w-[18rem] md:w-[25rem]">
      <div className=" h-[19rem] bg-white rounded-[1.5rem] flex flex-col gap-4 overflow-y-auto">
        <div className="w-full flex flex-col gap-4 px-4 py-2">
          <div className="flex flex-col gap-1 mt-2">
            <div className="flex gap-2 justify-start items-center">
              <label htmlFor="characterType" className="w-40 text-start text-sm font-bold text-storyblue">
                Character Type:
                {<span className="text-red-500 text-left text-xs italic">* </span>}
              </label>
              <select
                id="characterType"
                name="characterType"
                value={characterOutlineByUser?.characterType || ""}
                required
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className="w-full h-9 text-center border-gray-300 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              >
                {makeDropdownOptions(CharacterType())}
              </select>
            </div>
            {errors?.characterType && (
              <div className="text-red-500 text-left text-xs italic">{errors?.characterType}</div>
            )}
          </div>

          {!(
            characterOutlineByUser?.characterType === CHARACTER_TYPE_ANIMAL ||
            characterOutlineByUser?.characterType === CHARACTER_TYPE_FICTIONAL
          ) && (
            <>
              <div className="flex flex-col gap-1">
                <div className="flex w-full gap-2">
                  <label
                    htmlFor="age"
                    className="w-24 h-9 text-center font-bold text-sm text-storyblue flex items-center"
                  >
                    Age: <span className="text-red-500 text-xs italic ">* </span>
                  </label>
                  <input
                    id="age"
                    name="age"
                    type="number"
                    max="100"
                    min="1"
                    value={characterOutlineByUser?.age}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    required
                    className="w-[33%] text-center border-gray-300 border rounded-md focus:ring-indigo-500 focus:border-indigo-500 ml-3 "
                  />
                  <select
                    id="ageUnit"
                    name="ageUnit"
                    value={characterOutlineByUser?.ageUnit}
                    defaultValue={CHARACTER_AGE_UNIT}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    required
                    className="w-[33%] text-center border-gray-300 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value={CHARACTER_AGE_UNIT}>Years </option>
                    <option value="month">Months </option>
                  </select>
                </div>
                {errors?.age && <div className="text-red-500 text-left text-xs italic">{errors?.age}</div>}
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 justify-start items-center">
                  <label htmlFor="gender" className="w-40 text-start text-sm font-bold text-storyblue">
                    {/* Gender:{" "}
                    {continueWithoutImage && (
                      <span className="text-red-500 text-left text-xs italic ">* </span>
                    )} */}
                    Gender: <span className="text-red-500 text-left text-xs italic ">* </span>
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={characterOutlineByUser?.gender || ""}
                    required={continueWithoutImage}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    className="w-full h-9 text-center border-gray-300 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <option value=""></option>
                    <option value="boy">Boy</option>
                    <option value="girl">Girl</option>
                  </select>
                </div>
                {errors?.gender && (
                  <div className="text-red-500 text-left text-xs italic">{errors?.gender}</div>
                )}
              </div>
            </>
          )}

          {characterOutlineByUser?.characterType === CHARACTER_TYPE_ANIMAL && (
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 justify-start items-center">
                <label htmlFor="animalType" className="w-40 text-start text-sm font-bold text-storyblue">
                  Animal Type:
                  <span className="text-red-500  text-xs italic ">* </span>
                </label>
                <textarea
                  type="text"
                  name="animalType"
                  rows={2}
                  className="w-full max-h-10 rounded-md text-sm p-2 border border-gray-300"
                  value={characterOutlineByUser.animalType || ""}
                  required
                  onChange={(e) => handleInputChange(e)}
                  placeholder={`ex: Dog, Lion, Goldfish`}
                ></textarea>
              </div>
              {errors?.animalType && (
                <div className="text-left text-red-500 text-xs italic">{errors?.animalType}</div>
              )}
            </div>
          )}

          {characterOutlineByUser?.characterType === CHARACTER_TYPE_FICTIONAL && (
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 justify-start items-center">
                <label htmlFor="character" className="w-40 text-start text-sm font-bold text-storyblue">
                  Character
                  <span className="text-red-500  text-xs italic ">* </span>
                </label>
                <textarea
                  type="text"
                  name="character"
                  rows={2}
                  className="w-full max-h-10 rounded-md text-sm p-2 border border-gray-300"
                  value={characterOutlineByUser.character || ""}
                  required
                  onChange={(e) => handleInputChange(e)}
                  placeholder={`ex: Dragon, Superhero, Fairy`}
                ></textarea>
              </div>
              {errors?.character && (
                <div className="text-left text-red-500 text-xs italic">{errors?.character}</div>
              )}
            </div>
          )}

          <div className="flex flex-col gap-1">
            <div className="flex gap-2 justify-start items-center">
              <label htmlFor="mics" className="w-40 text-start text-sm font-bold text-storyblue">
                {characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN
                  ? "Clothing and Description: "
                  : "Description: "}
                {/* {characterOutlineByUser?.characterType != CHARACTER_TYPE_HUMAN || continueWithoutImage ? (
                  <span className="text-red-500  text-xs italic ">* </span>
                ) : (
                  ""
                )} */}

              
                  <span className="text-red-500  text-xs italic ">* </span>
               
              </label>
              <textarea
                type="text"
                name="mics"
                rows={5}
                className="w-full max-h-24 rounded-md text-sm p-1 border border-gray-300 text-justify"
                value={characterOutlineByUser?.mics || ""}
                required={
                  characterOutlineByUser?.characterType != CHARACTER_TYPE_HUMAN || continueWithoutImage
                }
                onChange={(e) => handleInputChange(e)}
                placeholder={` ${
                  characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN
                    ? "I am wearing ..."
                    : characterOutlineByUser?.characterType === CHARACTER_TYPE_ANIMAL
                    ? "ex: Blue eyes, spots, stripes"
                    : characterOutlineByUser?.characterType === CHARACTER_TYPE_FICTIONAL
                    ? "ex: Spiderman, with a cape, holding a lightsaber"
                    : ""
                }`}
              ></textarea>
            </div>
            {errors?.mics && <div className="text-left text-red-500 text-xs italic">{errors?.mics}</div>}
          </div>
        </div>
      </div>

      <i className="text-red-500 whitespace-nowrap ml-4 text-xs text-left">* Required</i>
      <div className="flex gap-2 items-center justify-start px-4">
        <label
          htmlFor="mics"
          className="w-40 text-start text-sm font-bold text-storyblue whitespace-pre-wrap"
        >
          {/* {previewImage ? "Upload" : "Replace"} */}
          {previewImage === "./dummy.png" ? "Upload" : "Replace"}
          <br />
          Photo:
        </label>

        <div className="w-full flex gap-2">
          {storyData?.uploadedImage !== null && storyData?.uploadedImage !== "null" && (
            <div
              className="relative flex justify-center items-center"
              onMouseEnter={() => setHoveredOnImage(true)}
              onMouseLeave={() => setHoveredOnImage(false)}
            >
              <img
                // src={previewImage}
                src={storyData?.uploadedImage}
                alt="preview"
                className="w-[7rem] h-[4.5rem] rounded-xl border-white border-2 object-cover"
                style={{ filter: hoveredOnImage ? "brightness(50%)" : "none" }}
              />
              {/* {hoveredOnImage && !storyData?.isDummyPic && (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="absolute top-6 right-6 text-white text-2xl cursor-pointer"
                  onClick={() => {
                    handleDeleteImage();
                  }}
                />
              )} */}
            </div>
          )}
          <Uploader
            onFileChange={handleImageChange}
            setPreviewImage={setPreviewImage}
            previewImage={previewImage}
            selectedImage={selectedImage}
            currentPortrait={currentPortrait}
            setShouldCoverPortrait={setShouldCoverPortrait} // handleOpenModal={handleOpenModal}
            // handleOpenModal={()=>setIsImageModalOpen(true)}
          />

          {/* {isImageModalOpen === true && (
            <ImageCropModal
              currentPortrait={currentPortrait}
              onClose={handleCloseModal}
              onCrop={handleCrop}
            />
          )} */}
        </div>
      </div>

      <div className="border-red-900 flex justify-center md:justify-end items-baseline gap-4 px-4">
        <p className="text-justify text-[11px] w-[15.5rem] italic">
          <strong>Please Note</strong>
          {continueWithoutImage
            ? " it is not required to upload a photo, but this will improve accuracy of character."
            : " adding more information regarding your character provides best results."}
        </p>
      </div>
    </div>
  );
};

export default CharacterDescriptionInputs;
